/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { ADD_ACCOUNT, LOGO, MODAL } from 'constants/ids';
import {
  REGULAR_KEYS,
  addEvent,
  eventKey,
  eventTarget,
  findNode,
  getFocusableElements,
  removeEvent,
} from '@nettoken/helpers';

import { hideOverlay, showOverlay } from 'main/overlay';
import { hideTutorial } from 'main/tutorial';
import CSSVars from 'environment/variables';
import { DASHBOARD_TUTORIAL_STEPS } from '../../constants/ids';

import {
  RXModalAnimationEnter,
  RXModalAnimationLeave,
  RXModalData,
  RXModalFocusPop,
  RXModalFocusPush,
  RXModalTemplate,
} from './reduxActions';

import { getModalTemplate } from './reduxState';


// TODO: Extract some of the generic UI functionality into a separate main/ui
// Which functions are generic??

let hasOverlay = false;

// TODO move these into ui.reducer too
let callbackArr = [];
let modalNodesArr = [];

const AsyncLeaveAnimation = () => dispatch => {
  dispatch(RXModalAnimationLeave());
  return new Promise(resolve => setTimeout(resolve, CSSVars.trMove));
};

const getFirstAndLastFocusableNodes = () => {
  const last = modalNodesArr[modalNodesArr.length - 1];
  const [first] = getFocusableElements(last);
  return { first, last };
};

/**
 * @param {string} type
 * @param {function} fn
 */
export const addModalKeyPress = (type, fn) => {
  const len = callbackArr.length;

  if (!len) {
    addEvent(document, 'keydown', handleKeyPress);
  }

  if (typeof fn === 'function') {
    const action = { fn, type };
    let sidebarActionInserted = false;

    if (type === 'sidebar') {
      for (let i = len - 1; i >= 0; i -= 1) {
        const actionInStack = callbackArr[i];
        if (actionInStack.type === 'modal') {
          callbackArr = [
            ...callbackArr.slice(0, i),
            action,
            ...callbackArr.slice(i),
          ];
          sidebarActionInserted = true;
          break;
        }
      }
    }

    if (type === 'modal' || !sidebarActionInserted) {
      callbackArr = [
        ...callbackArr,
        action,
      ];
    }
  }
};

/**
 * @param {string} type
 */
export const focusModal = type => {
  const id = type || MODAL;
  const node = findNode(`#${id}`, true);
  if (node) {
    modalNodesArr = [
      ...modalNodesArr,
      node,
    ];
  }

  const len = modalNodesArr.length;
  if (len) {
    const { first, last } = getFirstAndLastFocusableNodes();
    if (first) {
      first.focus();
    }
    else {
      last.setAttribute('tabindex', '0');
      last.focus();
    }
    addEvent(document, 'focus', handleFocus, true);
  }
};

/**
 * @param {object} event
 */
export const handleFocus = event => {
  const len = modalNodesArr.length;
  if (len && !modalNodesArr[len - 1].contains(eventTarget(event))) {
    const { first, last } = getFirstAndLastFocusableNodes();
    if (first) {
      first.focus();
    }
    else {
      last.focus();
    }
    event.stopPropagation();
  }
};

/**
 * @param {object} event
 */
export const handleKeyPress = event => {
  let matched = true;

  switch (eventKey(event)) {
    case REGULAR_KEYS.ESCAPE: {
      const len = callbackArr.length;
      if (len) callbackArr[len - 1].fn();
      break;
    }
    default:
      matched = false;
      break;
  }

  if (matched) event.preventDefault();
};

export const hideModal = () => dispatch => {
  if (hasOverlay) {
    dispatch(hideOverlay());
    hasOverlay = false;
  }

  dispatch(AsyncLeaveAnimation())
    .then(() => {
      unfocusModal();
      dispatch(RXModalTemplate());
      dispatch(RXModalData());
      dispatch(readFocusedElement());
    });
};

/**
 * Switch back element focus to the previous state.
 *
 * @returns {boolean}
 */
export const readFocusedElement = () => (dispatch, getState) => {
  const { focusedElements: nodes } = getState().ui;
  const len = nodes.length;
  if (len) {
    dispatch(RXModalFocusPop());

    // If the focus returns back to Add Account button, focus logo instead
    // because we don't want to automatically expand the Remove Account
    // button in submenu, it looks unaesthetical. :/
    const nextElement = nodes[len - 1];
    const hasAddAccountButton = nextElement.querySelector(`#${ADD_ACCOUNT}`);
    if (hasAddAccountButton) {
      const logo = document.getElementById(LOGO);
      if (logo) logo.focus();
    }
    else {
      // nextElement.focus();
    }

    return true;
  }
  return false;
};

export const removeModalKeyPress = () => {
  const len = callbackArr.length;
  if (len) {
    callbackArr = [
      ...callbackArr.slice(0, len - 1),
    ];
  }

  // Do not use `len` as we just changed the value.
  if (!callbackArr.length) {
    removeEvent(document, 'keydown', handleKeyPress);
    removeEvent(document, 'focus', handleFocus, true);
  }
};

/**
 * @param {string} nextTemplate
 * @param {any} [data=null]
 */
export const showModal = (nextTemplate, data = null) => dispatch => {
  if (!nextTemplate) return;
  dispatch(hideTutorial(DASHBOARD_TUTORIAL_STEPS, true));

  dispatch(RXModalFocusPush());

  /*
   * If we are opening a modal from another modal,
   * we do not want to save the previous element focus.
   */
  const prevTemplate = dispatch(getModalTemplate());
  if (prevTemplate && prevTemplate !== nextTemplate) {
    dispatch(RXModalFocusPop());
  }

  if (!hasOverlay) {
    dispatch(showOverlay());
    hasOverlay = true;
  }

  if (nextTemplate === 'connection-lost') {
    dispatch(RXModalTemplate(nextTemplate));
    // dispatch(RXModalData(data));
  }
  else {
    dispatch(RXModalData(data));
    dispatch(RXModalTemplate(nextTemplate));
  }

  findNode(`#${MODAL}`)
    .then(() => {
      dispatch(RXModalAnimationEnter());
      focusModal();
      document.getElementsByTagName('body')[0].blur();
    });
};

/**
 * @returns {boolean}
 */
export const unfocusModal = () => {
  const len = modalNodesArr.length;
  if (len) {
    modalNodesArr = [
      ...modalNodesArr.slice(0, len - 1),
    ];
    return true;
  }
  return false;
};
