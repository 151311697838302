/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MASTER_KEY_BACKUP_EXT } from 'constants/misc';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Script from 'react-load-script';
import { eventTarget } from '@nettoken/helpers';
import { recoverMasterKey } from 'main/backup';
import { hideModal } from 'main/modal';
import env from 'environment/env';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';

class ModalRecoverMasterKeyComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accept: `application/${MASTER_KEY_BACKUP_EXT}`,
      backupFile: null,
      error: props.t('error.masterKey'),
      file: '',
      input: '',
      isLoading: false,
      pdf: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handlePDFjsLoaded = this.handlePDFjsLoaded.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', this.props.hide, false);
  }

  async getBackup() {
    let value = this.state.input;
    if (!value) {
      const url = this.getPDFUrl();
      value = await this.getBackupFromPDF(url);
    }
    return value;
  }

  async getBackupFromPDF(url) {
    const { pdf } = this.state;
    let doc;
    try {
      doc = await pdf.getDocument(url);
    }
    catch (e) {
      return null;
    }

    const page = await doc.getPage(1);
    const operations = await page.getOperatorList();
    const limit = operations.fnArray.length;

    for (let i = 0; i < limit; i += 1) {
      const operationCode = operations.fnArray[i];
      /*
      if (this.isPDFElementImage(operationCode)) {
        const [imageName] = operations.argsArray[i];
        const image = page.objs.get(imageName);
        if (this.isImageSquare(image)) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const imageData = ctx.createImageData(image.width, image.height);
          imageData.data.set(image.data);
          ctx.putImageData(imageData, 0, 0);

          const img = new Image();
          img.height = image.height;
          img.src = canvas.toDataURL();
          console.log(img.src);
          // img.src = imageCode;
          img.width = image.width;
          document.body.appendChild(img);

          setTimeout(() => {
            import('@zxing/library').then(ZXing => {
              console.log(imageData, image);
              const reader = new ZXing.BrowserQRCodeReader();
              reader.decodeFromImage(img)
                .then(a => console.log(a))
                .catch(a => console.log(a));
            });
          }, 10);
        }
      }
      */

      if (this.isPDFElementText(operationCode)) {
        const [chars] = operations.argsArray[i];
        let str = '';
        chars.forEach(char => {
          str += char.unicode;
        });

        const BACKUP_NEEDLE = 'Backup key: ';
        if (str.startsWith(BACKUP_NEEDLE)) {
          return str.substr(BACKUP_NEEDLE.length);
        }
      }
    }

    return null;
  }

  getPDFUrl() {
    const type = this.state.accept;
    const blob = new Blob([this.state.backupFile], { type });
    const url = window.URL.createObjectURL(blob);
    return url;
  }

  handleChange(event) {
    const { name, value } = eventTarget(event);
    if (name === 'input') {
      this.setState({ [name]: value });
    }
    else if (name === 'file') {
      const [file] = eventTarget(event).files;
      if (file) {
        this.setState({ [name]: file.name, backupFile: file });
      }
    }
  }

  handlePDFjsLoaded() {
    this.setState({ pdf: window.pdfjsLib });
  }

  async handleSubmit(event) {
    event.preventDefault();

    this.setState({ isLoading: true });
    this.props.hide();

    // try {
    //   let input = await this.getBackup();
    //   if (input) {
    //     this.setState({ input });

    //     // Trim any whitespace.
    //     input = input.replace(/ /g, '');
    //     const masterKey = await this.props.recoverMasterKey(input, this.props.accessToken);
    //     this.setState({ isLoading: false });
    //     this.props.hide();
    //   }
    //   else {
    //     const error = this.props.t('error.backupFile');
    //     this.setState({ error, isLoading: false });
    //   }
    // }
    // catch (e) {
    //   console.log(e);
    // }
  }

  isImageSquare(image) {
    return image.width === image.height;
  }

  isPDFElementImage(operationCode) {
    const { pdf } = this.state;
    return operationCode === pdf.OPS.paintImageXObject;
  }

  isPDFElementText(operationCode) {
    const { pdf } = this.state;
    return operationCode === pdf.OPS.showText;
  }

  render() {
    const disabled =
      !this.state.pdf ||
      this.state.isLoading ||
      (!this.state.file && !this.state.input);

    return (
      <React.Fragment>
        <Container
          accept={this.state.accept}
          disabled={disabled}
          error={this.state.error}
          keyFile={this.state.file}
          keyInput={this.state.input}
          loading={this.state.isLoading}
          onCancel={this.props.hide}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          t={this.props.t} />

        <Script
          onLoad={this.handlePDFjsLoaded}
          url={env.pdfjsUrl} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  accessToken: (state.ui.modalData || {}).accessToken,
});

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(hideModal()),
  recoverMasterKey: (code, accessToken) => dispatch(recoverMasterKey(code, accessToken)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(ModalRecoverMasterKeyComponent)));
