/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { MODAL_IMPORT_PREVIEW } from 'constants/modal';
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { eventTarget } from '@nettoken/helpers';
import Routes from 'config/routes';
import { processFile } from 'main/import';
import { showModal } from 'main/modal';
import { goTo } from 'main/router';
import CSSVars from 'environment/variables';
import withOverlayAction from 'Overlay/withAction';
import Container from './container';

class SidebarImportComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      file: null,
      filename: '',
      isSubmitting: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.addOverlayAction('sidebar', this.props.goBack);
  }

  handleChange(event) {
    const [file] = eventTarget(event).files;
    if (file) {
      this.setState({ file, filename: file.name });
    }
  }

  async handleSubmit(event) {
    if (event) event.preventDefault();

    this.setState({ error: '', isSubmitting: true });

    const {
      apps,
      error,
      groupIds,
      groups,
    } = await processFile({
      credentials: this.props.credentials,
      file: this.state.file,
      groups: this.props.groups,
      t: this.props.t,
      unsortedId: this.props.unsortedId,
    });

    if (error !== null) {
      this.showError(error);
    }
    else {
      const modalData = { apps, groupIds, groups };
      this.setState({ isSubmitting: false });
      this.props.goToDashboard();
      setTimeout(() => this.props.showPreview(modalData), CSSVars.trMove * 2);
    }
  }

  showError(err) {
    const error = typeof err === 'object' ? err.message : this.props.t('error.import');
    this.setState({ error, isSubmitting: false });
  }

  render() {
    return (
      <Container
        error={this.state.error}
        filename={this.state.filename}
        isSubmitting={this.state.isSubmitting}
        onCancel={this.props.goBack}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        t={this.props.t} />
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.credentials.data,
  groups: state.groups.data,
  unsortedId: state.groups.unsortedGroupId,
});

const mapDispatchToProps = dispatch => ({
  goBack: () => dispatch(goTo(Routes.SETTINGS)),
  goToDashboard: () => dispatch(goTo(Routes.DASHBOARD)),
  showPreview: data => dispatch(showModal(MODAL_IMPORT_PREVIEW, data)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(withOverlayAction(SidebarImportComponent)));
