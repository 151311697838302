/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import {
  URL_CREDENTIAL,
  URL_CREDENTIALS_ORDER,
  URL_GROUP,
  URL_GROUPS,
  URL_USERS,
  URL_GROUPS_ORDER,
  DOWNWARD,
} from 'constants/endpoints';
import React from 'react';
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';
import classNames from 'classnames';
import { List } from 'immutable';
import { handleAriaKeyPress, noop } from '@nettoken/helpers';
import { pendingInvitationGroupId } from 'main/vault/credentials';
import {
  deleteGroup,
} from 'main/vault/groups';
import Tooltip from 'Tooltip';
import CSSVars from 'environment/variables';
import FLIP from 'utils/FLIP';
import { isTest } from 'utils/misc';
import { MOVE_CHECK_SCROLLBAR, MOVE_START_WORD, MOVE_STOP_WORD } from 'utils/move';
import Dropdown from 'Dropdown';
import Icon from 'Icon';
import IconContainer from 'Icon/container';
import imgs from '../../assets/images/indicatorPendingInvites.png';
import Styles from './style.css';

class GroupContainer extends React.Component {
  constructor(props) {
    super(props);
    this.flip = null;
    this.flipArrow = null;
  }

  componentDidMount() {
    const { arrow, groupWrapper } = this.refs;
    if (groupWrapper) {
      this.flip = new FLIP(groupWrapper, CSSVars.trMove);
    }
    if (arrow) {
      this.flipArrow = new FLIP(arrow, CSSVars.trMove);
    }
  }

  // We use FLIP to animate groups when their props change.
  componentDidUpdate(prevProps) {
    if (this.flip) {
      const { items } = this.props;
      const changedItemsLength = items.length !== prevProps.items.length;
      if (changedItemsLength && prevProps.items.length && items.length) {
        this.flip.play();
      }

      const changedOpenState = prevProps.open !== this.props.open;
      const { arrow } = this.refs;

      // Arrow might not have existed before.
      if (!this.flipArrow && arrow) {
        this.flipArrow = new FLIP(arrow, CSSVars.trMove);
      }
      // Arrow might not exist anymore.
      else if (this.flipArrow && !arrow) {
        this.flipArrow = null;
      }

      if (changedOpenState) {
        this.flip.play();
        if (this.flipArrow) this.flipArrow.play();
      }
    }
  }

  UNSAFE_componentWillUpdate() { // eslint-disable-line camelcase
    if (this.flip) this.flip.first();
    if (this.flipArrow) this.flipArrow.first();
  }

  getRemainder(items) {
    const max = CSSVars.groupItemsPerRow;
    const integerDivision = Math.floor((items.length - 1) / max);
    const remainder = integerDivision >= max ? max : (integerDivision + 1);
    return remainder;
  }

  isScrollable(items, remainder) {
    // We can vertically scroll only groups with 17+ apps.
    const max = CSSVars.groupItemsPerRow;
    const scrollable = remainder === max && ((items.length / max) > max);
    return scrollable;
  }

  onClickDeleteGroupPen() {
    const id = 'd6c6f624109e45f6b495a950aa5c3f98';
    const req = new Request(URL_GROUP);
    req.setUrlParams({ id }).authorise().delete().catch(e => console.log(e));
  }

  isUnsorted() {
    const { id, unsortedId } = this.props;
    return unsortedId === id || !id;
  }

  renderItems() {
    const { items, t } = this.props;
    const unsorted = this.isUnsorted();
    return items.map(item => {
      if (isTest()) {
        return (
          <IconContainer
            id={item.id}
            key={item.id}
            label={item.label}
            t={t} />
        );
      }
      const dashboardSpaceId = 'dashboardSpaceId' in item ?
        item.dashboardSpaceId :
        '';
      return (
        <Icon
          externalAccount={item.externalAccount}
          sharedByUserName={item.sharedByUserName}
          sharedByUserPhone={item.sharedByUserPhone}
          shared={item.shared}
          accepted={item.accepted}
          invitationId={item.invitationId}
          cookieUrl={item.cookieUrl}
          disableAppInfo={this.props.disableAppInfo}
          disableAppMove={this.props.disableAppMove}
          disableAppOpen={this.props.disableAppOpen}
          domain={item.domain}
          draggable={this.props.draggable}
          email={item.email}
          groupId={item.groupId}
          id={item.id}
          key={item.id}
          loginUrl={item.loginUrl}
          name={item.name}
          note={item.note}
          onSelect={this.props.onSelectApp}
          password={item.password}
          phone={item.phone}
          picture={item.picture}
          privacyUrl={item.privacyUrl}
          publicKey={item.publicKey}
          secretKey={item.secretKey}
          accountId={item.accountId}
          selected={this.props.selectedApps.includes(item.id)}
          t={t}
          dashboardSpaceId={dashboardSpaceId}
          username={item.username}
          isUnsorted={unsorted} />
      );
    });
  }

  /*
   * Wrapped in extra <div> container because when we have multiple groups in a row and
   * we expand one of them, the parent wrapper expands for every node in the row. This
   * would then cause undesired bugs when dragging items if we used the parent wrapper
   * for calculations.
   */
  render() {
    const { t, externalDashboard } = this.props;
    let titttle = this.props.title;
    const items = this.renderItems();
    const isUnsorted = this.isUnsorted();
    const remainder = this.getRemainder(items);
    let shows = this.props.editable;
    let cssss = false;
    let hidepend = true;
    if (this.props.id === pendingInvitationGroupId()) {
      shows = false;
      cssss = true;
      titttle = 'Pending Invitations';
      hidepend = this.props.items.length !== 0;
    }
    if (externalDashboard) {
      shows = false;
    }
    return (
      <div
        className={classNames({
          [Styles.wrapper]: hidepend,
          [Styles._default]: hidepend,
          [Styles.cursor11]: true,
          // A single icon in unsorted should be centered.
          [Styles.centerIcons]: this.props.flexible && items.length === 1,
          [Styles.flexible]: this.props.flexible,
          [Styles['has-transition']]: isUnsorted,
          // Invisible prop is used only in unsorted group.
          [Styles.visible]: !this.props.invisible,
        })}
        ref="node">
   { hidepend ?
        <div {...this.props.spreadProps}>
          <div className={Styles.header}>
            {this.props.dropdownOpen && <Dropdown
              items={new List(this.props.dropdownItems)}
              onClickItem={this.props.onClickDropdownItem}
              selected={this.props.selectedDropdown}
              showCustomEntry={this.props.dropdownShowCustomEntry}
              t={t} />}

            {
              shows ?
                <input
                  aria-label={t('placeholder.group')}
                  autoComplete="off"
                  className={classNames({
                    [Styles.title]: true,
                    [MOVE_STOP_WORD]: true,
                    [Styles.error]: !this.props.unique,
                  })}
                  name="label"
                  onBlur={this.props.onBlurTitle}
                  onChange={this.props.onChangeTitle}
                  onFocus={this.props.onFocusTitle}
                  placeholder={this.props.placeholder || t('placeholder.groupTitle')}
                  type="text"
                  value={titttle} /> :
                <h2 className={classNames({
                  [Styles.title]: true,
                  [MOVE_STOP_WORD]: true,
                  [Styles.error]: !this.props.unique,
                  [Styles.input]: true,
                  [Styles.cursor11]: true,
                })}>
                  {titttle || this.props.placeholder}
                </h2>
            }
            {
               <span
              className={classNames({
                [Styles.disp]: !cssss || externalDashboard,
              })} >
               <Tooltip delay={0} placement='top' trigger={['hover']} mouseEnterDelay={0} mouseLeaveDelay={0}
                overlay={<div style={{ color: 'white', fontSize: '10px' }}>You’ve got mail! <br/><br/>
                You have new accounts shared with you. Click <br/>the accounts to accept
                or deny access to the<br/> login information.</div>}>
                <img src={imgs} width='40' height='32' style={{ marginRight: '-37px' }} /></Tooltip>
               </span>
            }
            { (this.props.groupId === pendingInvitationGroupId() ||
              externalDashboard) ? null : <span
              className={classNames({
                [Styles.counter]: true,
                [Styles._active]: this.props.canDeleteGroup,
                [Styles._iconDelete]: true,
              })}
              onClick={this.props.onClickDeleteGroup}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 154.13 182.95">
                <g>
                  <path d="M112.09 28.22v-5.95C111.85 8.84 103.19.19 89.74.08 81.08 0 72.41 0 63.75.09 51.42.29 42.75 8.72 42.1 21c-.12 2.26 0 4.54 0 7.36H0V42h13.85V159.1c0 15.34 8.46 23.8 23.79 23.81h78.49c16 0 24.19-8.3 24.2-24.39V42.1h13.79V28.22zM98.36 28H55.85a60.83 60.83 0 0 1 0-7c.51-4.12 2.82-6.79 7.16-6.85 9.31-.11 18.61-.07 27.92 0 4.37 0 6.73 2.57 7.33 6.66a52.46 52.46 0 0 1 .1 7.19z" />
                </g>
              </svg>
            </span>}

            <Tooltip content='total accounts in this group!'>
             { cssss || externalDashboard ?
               <span
                className={classNames({
                  [Styles.showss]: true,
                  [Styles.draggable]: this.props.draggable && !isUnsorted,
                  [Styles.draggable1]: cssss || externalDashboard,
                  [MOVE_START_WORD]: this.props.draggable && !isUnsorted,
                })}
                onClick={
                  e => this.props.flagShuffleGroups && this.props.onClickFlagShuffleGroups(e)
                }>
                {this.props.counter}
              </span> : <span
                className={classNames({
                  [Styles.counter]: true,
                  [Styles.draggable]: this.props.draggable && !isUnsorted,
                  [Styles.draggable1]: cssss || externalDashboard,
                  [MOVE_START_WORD]: this.props.draggable && !isUnsorted,
                })}
                onClick={
                  e => this.props.flagShuffleGroups && this.props.onClickFlagShuffleGroups(e)
                }>
                {this.props.counter}
              </span> }

            </Tooltip>
          </div>
          <div
            className={classNames({
              [Styles.groupWrapper]: true,
              [Styles.draggable]: this.props.draggable && !isUnsorted,
              [Styles.draggable1]: cssss || externalDashboard,
              [Styles.expanded]: this.props.open,
              [Styles[`expanded${remainder}`]]: this.props.open,
              [Styles.scrollable]: this.isScrollable(items, remainder),
            })}
            ref="groupWrapper">
            <FlipMove
              className={classNames({
                [Styles.group]: true,
                [Styles.group11]: cssss || externalDashboard,
                [Styles.draggable]: this.props.draggable && !isUnsorted,
                [Styles.draggable1]: cssss || externalDashboard,
                [MOVE_CHECK_SCROLLBAR]: !isUnsorted,
              })}
              duration={CSSVars.trMove}
              easing="ease"
              leaveAnimation={null}
              ref="scrollable">
              {items}
            </FlipMove>
          </div>
          {this.props.counter > CSSVars.groupItemsPerRow && <div className={Styles.arrowWrapper}>
            <Tooltip content='expand group'>
              <i
                className={classNames(Styles.arrow, this.props.open && Styles.expanded)}
                onClick={this.props.onClickArrow}
                onKeyDown={e => handleAriaKeyPress(e) && this.props.onClickArrow(e)}
                ref="arrow"
                role="button"
                tabIndex="0" />
            </Tooltip>
          </div>}
        </div> : this.props.deleteGroup }
      </div>
    );
  }
}

GroupContainer.defaultProps = {
  counter: 0,
  draggable: true,
  dropdownItems: [],
  items: [],
  onClickFlagShuffleGroups: noop,
  open: false,
  placeholder: '',
  selectedApps: [],
  selectedDropdown: '',
  spreadProps: {},
  title: '',
  unsortedId: null,
};

GroupContainer.propTypes = {
  canDeleteGroup: PropTypes.bool,
  counter: PropTypes.number,
  disableAppInfo: PropTypes.bool,
  disableAppMove: PropTypes.bool,
  disableAppOpen: PropTypes.bool,
  draggable: PropTypes.bool,
  dropdownItems: PropTypes.array,
  dropdownOpen: PropTypes.bool,
  dropdownShowCustomEntry: PropTypes.bool,
  editable: PropTypes.bool.isRequired,
  flagShuffleGroups: PropTypes.bool,
  flexible: PropTypes.bool,
  id(props, propName, componentName) {
    const propValue = props[propName];
    if (propValue === null) return;
    if (typeof propValue === 'string') return;
    throw new Error(`${componentName} prop id only accepts null or string.`);
  },
  invisible: PropTypes.bool,
  items: PropTypes.array,
  onBlurTitle: PropTypes.func,
  onChangeTitle: PropTypes.func,
  onClickArrow: PropTypes.func.isRequired,
  onClickDeleteGroup: PropTypes.func,
  onClickDropdownItem: PropTypes.func,
  onClickFlagShuffleGroups: PropTypes.func,
  onFocusTitle: PropTypes.func,
  onSelectApp: PropTypes.func,
  open: PropTypes.bool,
  placeholder: PropTypes.string,
  selectedApps: PropTypes.array,
  selectedDropdown: PropTypes.string,
  spreadProps: PropTypes.object,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  unique: PropTypes.bool.isRequired,
  unsortedId(props, propName, componentName) {
    const propValue = props[propName];
    if (propValue === null) return;
    if (typeof propValue === 'string') return;
    throw new Error(`${componentName} only accepts null or string.`);
  },
};

export default GroupContainer;
