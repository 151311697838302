/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { f2 } from 'constants/flags';
import { TUTORIAL_SORT_CREDENTIALS } from 'constants/ids';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { eventTarget, findNode, includesCaseInsensitive } from '@nettoken/helpers';
import { LABEL_UNNAMED_GROUP } from '@nettoken/models';
import { getFlag } from 'main/flags/reduxState';
import { isAddingCredential } from 'main/router';
import { hideTutorial, showTutorial } from 'main/tutorial';
import { hasDoneTutorial } from 'main/tutorial/reduxState';
import { hasCompletedAddCredentialTutorial, pendingInvitationGroupId } from 'main/vault/credentials';
import { getOneCredential } from 'main/vault/credentials/reduxState';
import { refreshSearch } from 'main/search';
import {
  deleteGroup,
  shuffleGroups,
  updateGroup,
  updateGroupName,
} from 'main/vault/groups';

import { getUnsortedGroup } from 'main/vault/groups/reduxState';
import CSSVars from 'environment/variables';
import { MOVE_ATTRIBUTE, MOVE_CLASS_GROUP } from 'utils/move';
import Container from './container';

const categoryFilter = v => x => !v || x.toUpperCase().startsWith(v.toUpperCase());

class GroupComponent extends React.Component {
  constructor(props) {
    super(props);

    const { label, open } = props;
    const { items, showCustom } = this.getDropdownItems(label);
    const apps = this.filterItems();
    const max = CSSVars.groupItemsPerRow;

    this.state = {
      dropdownItems: items,
      dropdownShowCustomEntry: showCustom,
      isDropdownOpen: false,
      isUnique: true,
      label,
      open: open && apps.length > max ? open : false,
      selectedDropdown: '',
    };

    this.hasHint = false;
    this.timerHint = null;
    this.groupWrapper = React.createRef();

    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickArrow = this.handleClickArrow.bind(this);
    this.handleClickDropdownItem = this.handleClickDropdownItem.bind(this);
    this.handleDeleteGroup = this.handleDeleteGroup.bind(this);
    this.handleDeleteGroupPen = this.handleDeleteGroupPen.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.label !== this.props.label) {
      const { label } = this.props;
      const { items, showCustom } = this.getDropdownItems(label);
      this.setState({
        dropdownItems: items,
        dropdownShowCustomEntry: showCustom,
        label,
        selectedDropdown: '',
      });
    }
  }

  componentWillUnmount() {
    this.hideHint(true);
  }

  dropdownAddCurrentLabelIfUnique(items, label) {
    const index = includesCaseInsensitive(label, new List(items));
    let showCustom = false;

    if (label && index === -1) {
      items = [
        ...items,
        label,
      ];

      showCustom = true;
    }

    return { items, showCustom };
  }

  filterItems() {
    const { apps } = this.props;
    const { query } = this.props.search;
    const searchResults = apps ? [] : this.getSearchResults();
    const includeAllItems = apps ? true : this.searchMatchedGroupName();
    const limit = this.props.items.length;
    let added = 0;
    const items = [];
    const itemIds = [];

    // Uses for-loop so we can easily add rendering limits in the future.
    for (let i = 0; i < limit; i += 1) {
      const id = this.props.items[i];
      if (!apps) {
        const includeApp = !itemIds.includes(id) && (
          !query || includeAllItems || searchResults.includes(id)
        );
        if (includeApp) {
          const item = this.props.getOneCredential(id);
          if (item) {
            if (
              'dashboardSpaceId' in item &&
              item.dashboardSpaceId !== null &&
              Object.keys(this.props.dashboards).length > 0
            ) {
              const arrDasboard = Object.values(this.props.dashboards)
                .filter(dash => dash.id == item.dashboardSpaceId);
              if (arrDasboard.length <= 0) {
                item.dashboardSpaceId = null;
              }
            }
            if (
              'dashboardSpaceId' in item &&
              this.props.currentDashboard === item.dashboardSpaceId
            ) {
              added += 1;
              items.push(item);
              itemIds.push(id);
            }
            else if (
              !('dashboardSpaceId' in item) &&
              (this.props.currentDashboard === '' ||
              this.props.currentDashboard === null)
            ) {
              added += 1;
              items.push(item);
              itemIds.push(id);
            }
          }
        }
      }
      else if (!itemIds.includes(id) && apps[id]) {
        const item = { ...apps[id] };
        if (
          'dashboardSpaceId' in item &&
          item.dashboardSpaceId !== null &&
          Object.keys(this.props.dashboards).length > 0
        ) {
          const arrDasboard = Object.values(this.props.dashboards)
            .filter(dash => dash.id == item.dashboardSpaceId);
          if (arrDasboard.length <= 0) {
            item.dashboardSpaceId = null;
          }
        }
        if (
          'dashboardSpaceId' in item &&
          this.props.currentDashboard === item.dashboardSpaceId
        ) {
          added += 1;
          items.push(item);
          itemIds.push(id);
        }
        else if (
          !('dashboardSpaceId' in item) &&
          this.props.currentDashboard === ''
        ) {
          added += 1;
          items.push(item);
          itemIds.push(id);
        }
      }

      if (added === limit) break;
    }
    return items;
  }

  getAppsCount() {
    if (this.props.credentials && this.props.apps > this.props.credentials.length) {
      window.location.reload();
    }
    if (this.props.apps) {
      return this.filterItems().length;
    }

    if (this.props.search.query) {
      const includeAllItems = this.searchMatchedGroupName();
      if (includeAllItems) {
        return this.filterItems().length;
      }
      return this.getSearchResults().length;
    }
    return this.filterItems().length;
  }

  getDropdownItems(label) {
    const { t } = this.props;
    const key = 'categories';
    let items = t(key);
    if (!items || items === key) items = [];
    items = this.hideItemIfGroupExist(items);
    items = items.filter(categoryFilter(label));
    items = this.dropdownAddCurrentLabelIfUnique(items, label);
    return items;
  }

  // Hide label from dropdown if group with label already exist.
  hideItemIfGroupExist(items) {
    const { groups } = this.props;
    const dropdown = [
      'Books',
      'Business',
      'Education',
      'Entertainment',
      'Finance',
      'Food & drink',
      'Games',
      'Health & Fitness',
      'Lifestyle',
      'Medical',
      'Music',
      'Navigation',
      'News',
      'Photo & Video',
      'Productivity',
      'Shopping',
      'Social',
      'Sports',
      'Travel',
      'Utilities',
      'Weather',
    ];
    const groupWithAccounts = Object.values(groups)
      .filter(group => group.dashboardSpaceId === this.props.currentDashboard)
      .map(group => group.label);

    const groupList = new List(groupWithAccounts);
    return items.filter(item => {
      const index = includesCaseInsensitive(item, groupList);
      if (index === -1) {
        return true;
      }
      return false;
    });
  }

  getSearchResults() {
    return this.props.search.results[this.props.id] || [];
  }

  handleBlur() {
    // Leave time for onClick event to trigger if we clicked one of the children items.
    setTimeout(() => {
      if (!this.state.isUnique) {
        this.setState({
          isDropdownOpen: false,
          label: '',
          isUnique: true,
        }, () => {
          const { id } = this.props;
          this.props.updateGroupName({ id, name: '' });
        });
      }
      else {
        this.setState({ isDropdownOpen: false });
      }
    }, 200);
  }

  handleChange(event) {
    if (!event) return;
    let { value: name } = eventTarget(event);

    // Strip leading spaces.
    name = name.replace(/^\s+/g, '');

    // Value must have changed.
    if (name !== this.state.label) {
      const max = name.length;
      const isLastCharSpace = name[max - 1] === ' ';
      const hasManyTrailingSpaces = isLastCharSpace && max > 1 ? name[max - 2] === ' ' : false;

      // Do not allow more than 1 trailing space.
      if (!hasManyTrailingSpaces) {
        if (!isLastCharSpace) {
          this.updateDropdownItems(name);
        }

        // Update the displayed value.
        this.setState({ label: name }, () => {
          // Recalculate uniqueness and sync state with server only if the last
          // character is not a space.
          if (!isLastCharSpace) {
            const { id } = this.props;
            const isUnique = this.props.updateGroupName({ id, name });
            if (isUnique) this.hideHint(false);
            this.setState({ isUnique });
          }
        });
      }
    }
  }

  handleClickArrow() {
    const { open } = this.state;
    this.setState({ open: !open }, () => {
      const { id } = this.props;
      // If we closed the group, scroll back to top. We do not want to
      // freeze the position mid-scroll.
      if (open) {
        const { groupWrapper, scrollable } = this.refs.container.refs;
        const scrollableClassName = scrollable.props.className;
        const [scrollableDiv] = groupWrapper.getElementsByClassName(scrollableClassName);
        scrollableDiv.scrollTop = 0;
        groupWrapper.scrollTop = 0;
      }

      // Do not update application state if this group is only temporary.
      // We know that because it had `apps` supplied instead of `items`.
      this.props.updateGroup(id, { open: !open });

      this.hideHint(false);
    });
  }

  handleClickDropdownItem(value, submit = false) {
    this.setState({ selectedDropdown: value });
    if (submit) this.handleChange({ target: { value } });
  }

  handleDeleteGroup() {
    setTimeout(() => this.hideHint(false), CSSVars.trMove);
    const { id } = this.props;
    this.props.deleteGroup({ id });
  }

  handleDeleteGroupPen() {
    const id = 'd6c6f624109e45f6b495a950aa5c3f98';
    this.props.deleteGroup({ id });
  }

  handleFocus() {
    this.setState({ isDropdownOpen: true });
  }

  // Hides the tutorial if it exists.
  hideHint(incomplete) {
    if (!this.hasHint) return;
    clearTimeout(this.timerHint);
    this.props.hideTutorial(TUTORIAL_SORT_CREDENTIALS, incomplete);
    this.hasHint = false;
  }

  isUnsorted() {
    const { id, unsortedId } = this.props;
    return unsortedId === id || !id;
  }

  searchMatchedGroupName() {
    const groups = this.props.search.results.__groups__ || [];
    return groups.includes(this.props.id);
  }

  shouldHideGroup() {
    if (this.props.apps) return false;
    const includeAllItems = this.searchMatchedGroupName();
    const hasResults = includeAllItems || this.getSearchResults().length;
    const { query } = this.props.search;
    return !!query && !hasResults;
  }

  shouldShowHint() {
    // We don't want to show the hint if the user is
    // in process of adding an account.
    if (this.props.isAddingCredential() || this.props.search.query ||
      !this.props.hasCompletedAddCredentialTutorial()) {
      return false;
    }
    const isFirst = this.props.placeholder === LABEL_UNNAMED_GROUP;
    const isUnsorted = this.isUnsorted();
    if (isUnsorted || isFirst) {
      const unsortedGroup = this.props.getUnsortedGroup();
      const unsortedCredentials = unsortedGroup ? unsortedGroup.apps.length : 0;

      if (unsortedCredentials > 0) {
        const totalCredentials = Object.keys(this.props.credentials).length;
        if (isUnsorted && totalCredentials === unsortedCredentials) {
          return false;
        }
        if (isFirst && totalCredentials !== unsortedCredentials) {
          return false;
        }
        return !this.props.hasDoneTutorial(TUTORIAL_SORT_CREDENTIALS);
      }
    }

    return false;
  }

  async showHint() {
    if (this.hasHint) return;

    const { id } = this.props;
    const isUnsorted = this.isUnsorted();
    // Wait for the component to render.
    await findNode(`#${id}`);
    this.timerHint = setTimeout(() => {
      this.props.showTutorial({
        coords: {
          left: '100%',
          top: isUnsorted ? '40px' : '90px',
        },
        direction: 'left',
        type: 'advanced',
        placement: 'center',
        light: true,
        id,
        isFixed: false,
        name: TUTORIAL_SORT_CREDENTIALS,
        text: this.isUnsorted() ? 'sortCredentialUnsortedGroup' : 'sortCredentialFirstGroup',
      });
      this.hasHint = true;
    }, 350);
  }

  updateDropdownItems(label) {
    const { items, showCustom } = this.getDropdownItems(label);
    this.setState({ dropdownItems: items, dropdownShowCustomEntry: showCustom });
  }

  // Wrapped in extra <div> container because when we have multiple groups in a row and
  // we expand one of them, the parent wrapper expands for every node in the row. This
  // would then cause undesired bugs when dragging items if we used the parent wrapper
  // for calculations.
  render() {
    refreshSearch();
    const { draggable, id } = this.props;
    const shouldHide = this.shouldHideGroup();
    const isUnsorted = this.isUnsorted();
    const canDeleteGroup = !isUnsorted &&
      this.filterItems().length === 0 &&
      this.props.deletable &&
      !this.props.loading;

    if (shouldHide && !isUnsorted) {
      return null;
    }

    const props = {
      [MOVE_ATTRIBUTE]: id || 'null',
      id: id || 'null',
    };

    if (this.props.id === pendingInvitationGroupId() || this.props.externalDashboard) {
      props.className = false;
    }
    else {
      props.className = MOVE_CLASS_GROUP;
    }


    return (
      <Container
        canDeleteGroup={canDeleteGroup}
        counter={this.getAppsCount()}
        deletable={this.props.deletable}
        disableAppInfo={this.props.disableAppInfo}
        disableAppMove={this.props.disableAppMove}
        disableAppOpen={this.props.disableAppOpen}
        draggable={draggable}
        dropdownItems={this.state.dropdownItems}
        dropdownOpen={this.state.isDropdownOpen}
        dropdownShowCustomEntry={this.state.dropdownShowCustomEntry}
        editable={this.props.editable}
        flagShuffleGroups={this.props.flagShuffleGroups()}
        flexible={this.props.flexible}
        id={this.props.id}
        invisible={(isUnsorted && shouldHide) || this.props.invisible}
        items={this.filterItems()}
        onBlurTitle={this.handleBlur}
        onChangeTitle={this.handleChange}
        onClickArrow={this.handleClickArrow}
        onClickDeleteGroup={this.handleDeleteGroup}
        onClickDeleteGroupPen={this.handleDeleteGroupPen}
        onClickDropdownItem={this.handleClickDropdownItem}
        onClickFlagShuffleGroups={this.props.shuffleGroups}
        onFocusTitle={this.handleFocus}
        onSelectApp={this.props.onSelectApp}
        open={this.state.open}
        placeholder={this.props.placeholder}
        ref="container"
        selectedApps={this.props.selectedApps}
        selectedDropdown={this.state.selectedDropdown}
        spreadProps={props}
        t={this.props.t}
        title={this.state.label}
        unique={this.state.isUnique}
        unsortedId={this.props.unsortedId}
        externalDashboard={this.props.externalDashboard} />
    );
  }
}

GroupComponent.defaultProps = {
  deletable: true,
  draggable: true,
};

GroupComponent.propTypes = {
  // Override default apps for custom views.
  apps: PropTypes.object,
  deletable: PropTypes.bool,
  disableAppInfo: PropTypes.bool,
  disableAppMove: PropTypes.bool,
  disableAppOpen: PropTypes.bool,
  draggable: PropTypes.bool,
  editable: PropTypes.bool.isRequired,
  flexible: PropTypes.bool.isRequired,
  id(props, propName, componentName) {
    const propValue = props[propName];
    if (propValue === null) return;
    if (typeof propValue === 'string') return;
    throw new Error(`${componentName} only accepts null or string.`);
  },
  invisible: PropTypes.bool.isRequired,
  items: PropTypes.array,
  label: PropTypes.string.isRequired,
  onSelectApp: PropTypes.func,
  open: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  selectedApps: PropTypes.array,
  /** i18n translate method */
  t: PropTypes.func.isRequired,
  unsortedId(props, propName, componentName) {
    const propValue = props[propName];
    if (propValue === null) return;
    if (typeof propValue === 'string') return;
    throw new Error(`${componentName} only accepts null or string.`);
  },
};

const mapStateToProps = (state, props) => {
  const group = state.groups.data[props.id] || {};
  const items = (props.items !== undefined ? props.items : group.apps) || [];
  const externalDashboard = group.dashboardSpaceId &&
    state.ui.dashboards[group.dashboardSpaceId].externalDashboard;
  return {
    // Needed to re-render when credential data changes.
    credentials: state.credentials.data,
    // Needed to re-render when flags change.
    flags: state.flags,
    groups: state.groups.data || {},
    items,
    // Needed to re-render when url changes.
    router: state.router,
    search: state.search,
    // Needed to re-render when tutorial changes.
    tutorial: state.tutorial,
    _rendersOnGroupStateUpdate: group,
    currentDashboard: state.ui.currentDashboard,
    dashboards: state.ui.dashboards,
    externalDashboard,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteGroup: args => dispatch(deleteGroup(args)),
  flagShuffleGroups: () => dispatch(getFlag(f2.id)),
  getOneCredential: id => dispatch(getOneCredential(id)),
  getUnsortedGroup: () => dispatch(getUnsortedGroup()),
  hasCompletedAddCredentialTutorial: () => dispatch(hasCompletedAddCredentialTutorial()),
  hasDoneTutorial: name => dispatch(hasDoneTutorial(name)),
  hideTutorial: (name, incomplete) => dispatch(hideTutorial(name, incomplete)),
  isAddingCredential: () => dispatch(isAddingCredential()),
  showTutorial: args => dispatch(showTutorial(args)),
  shuffleGroups: () => dispatch(shuffleGroups()),
  updateGroup: (id, state) => dispatch(updateGroup(id, state)),
  updateGroupName: args => dispatch(updateGroupName(args)),
  refreshSearch: () => dispatch(refreshSearch()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupComponent);
