/*
 * Copyright (C) 2024 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */

/**
  * {@link config/env.json}
 *
 * branch: master
 */
/* eslint-disable */
export default {
  appleAppStoreUrl: 'https://apps.apple.com/gb/app/id1430010717',
  googleAnalyticsId: 'UA-69225214-4',
  googlePlayStoreUrl: 'https://play.google.com/store/apps/details?id=io.nettoken.nettokenapp',
  chromeExtensionUrl: 'https://chrome.google.com/webstore/detail/nettoken/gbhmppmhcijfhnkbknalegofnendfefp',
  googleSignInClientId: '1042242333611-n9ptvadqadu284opa1l0eh44ekqh9gb3.apps.googleusercontent.com',
  microsoftSignInClientId: 'bb11ac99-f847-409b-ae3f-003daec8a242',
  pdfjsUrl: 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.0.550/pdf.min.js',
  sentryUrl: 'https://c9c8d421bb8d49d38c04145af2e967ed@sentry.io/272371',
  slackWebhook: 'https://hooks.slack.com/services/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',
  extensionId: 'gbhmppmhcijfhnkbknalegofnendfefp',
  sentryUrl: 'https://6ffe0b32f57d4d80b3d61ee1c466564c@sentry.io/1225593',
  serverUrl: 'https://apinettoken.com/',
  webappUrl: 'https://nettoken.io/'
};
/* eslint-enable */
