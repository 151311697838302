/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { OVERLAY } from 'constants/ids';
import { findNode, scrollLock, scrollUnlock } from '@nettoken/helpers';
import CSSVars from 'environment/variables';

import {
  RXOverlayActions,
  RXOverlayAnimationEnter,
  RXOverlayAnimationLeave,
  RXOverlayRenderComponent,
  RXOverlayRenderNull,
} from './reduxActions';

const MOVE_SPEED = CSSVars.trMove;

// We show always only 1 overlay element, but this can be called from
// multiple modals and/or sidebar views. Keep count of the elements
// requesting overlay to be shown. When we ask to hide overlay, we
// subtract from this total and hide the overlay only when it's zero.
let overlayElementsCount = 0;

const AsyncLeaveAnimation = () => dispatch => {
  dispatch(RXOverlayAnimationLeave());
  return new Promise(resolve => setTimeout(resolve, MOVE_SPEED));
};

/**
 * @param {string} type
 * @param {function} fn
 */
export const addOverlayAction = (type, fn) => (dispatch, getState) => {
  let { overlayData } = getState().ui;
  const action = { fn, type };
  let sidebarActionInserted = false;

  if (type === 'sidebar') {
    for (let i = overlayData.length - 1; i >= 0; i -= 1) {
      const actionInStack = overlayData[i];
      if (actionInStack.type === 'modal') {
        overlayData = [
          ...overlayData.slice(0, i),
          action,
          ...overlayData.slice(i),
        ];
        sidebarActionInserted = true;
        break;
      }
    }
  }

  if (type === 'modal' || !sidebarActionInserted) {
    overlayData = [
      ...overlayData,
      action,
    ];
  }

  dispatch(RXOverlayActions(overlayData));
};

export const hideOverlay = () => dispatch => {
  overlayElementsCount -= 1;
  if (overlayElementsCount > 0) return;

  dispatch(AsyncLeaveAnimation())
    .then(() => {
      dispatch(RXOverlayRenderNull());
      scrollUnlock();
    });
};

/**
 * Pops the last action in the stack, could add support for index.
 *
 * @returns {integer}
 */
export const removeOverlayAction = () => (dispatch, getState) => {
  let { overlayData } = getState().ui;
  const len = overlayData.length;
  if (len) {
    overlayData = [
      ...overlayData.slice(0, len - 1),
    ];
  }
  dispatch(RXOverlayActions(overlayData));
  return overlayData.length;
};

export const showOverlay = () => dispatch => {
  overlayElementsCount += 1;

  scrollLock();
  dispatch(RXOverlayRenderComponent());

  findNode(`#${OVERLAY}`)
    .then(() => dispatch(RXOverlayAnimationEnter()));
};
