/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { hideModal, showModal } from 'main/modal';
import { Request } from 'utils/request';
import withOverlayAction from 'Overlay/withAction';
import addAccountHover from '../../../../assets/images/addAccountsHover.png';
import addAccountActive from '../../../../assets/images/addAccounts.png';
import moveAccountHover from '../../../../assets/images/moveAccountsHover.png';
import moveAccountActive from '../../../../assets/images/moveAccounts.png';
import Container from './container';
import { MODAL_ADD_ACCOUNT_NAVLIST, MODAL_DASHBOARD_CREATED, MODAL_MOVE_ACCOUNTS } from '../../../../constants/modal';

class ModalDashboardCreated extends React.Component {
  constructor(props) {
    super(props);

    this.hide = this.hide.bind(this);
    this.addAccounts = this.addAccounts.bind(this);
    this.moveAccounts = this.moveAccounts.bind(this);

    this.state = {
      buttons: [
        'Move Accounts',
        'Add Accounts',
      ],
    };
  }

  componentDidMount() {
    this.props.addOverlayAction('modal', () => this.hide());
  }


  hide() {
    this.props.hide();
  }

  addAccounts() {
    this.props.showModal(MODAL_ADD_ACCOUNT_NAVLIST);
  }

  moveAccounts() {
    this.props.showModal(MODAL_MOVE_ACCOUNTS, {
      backBtn: () => this.props.showModal(MODAL_DASHBOARD_CREATED),
    });
  }

  getIcons(index) {
    switch (index) {
      case 1:
        return addAccountActive;

      case 0:
      default:
        return moveAccountActive;
    }
  }

  getHoverIcons(index) {
    switch (index) {
      case 1:
        return addAccountHover;

      case 0:
      default:
        return moveAccountHover;
    }
  }

  render() {
    return (
      <Container
        onCancel={this.hide}
        buttons={this.state.buttons}
        dashboardName={this.props.dashboardName}
        totalAccounts={this.props.totalAccounts}
        addAccounts={this.addAccounts}
        moveAccounts={this.moveAccounts}
        getIcons={this.getIcons}
        getHoverIcons={this.getHoverIcons}
        />
    );
  }
}

const mapDispatchToState = state => ({
  currentDashboard: state.ui.currentDashboard,
  dashboardName: state.ui.dashboards[state.ui.currentDashboard].name,
  totalAccounts: Object.values(state.credentials.data)
    .filter(item => 'dashboardSpaceId' in item && item.dashboardSpaceId == state.ui.currentDashboard)
    .length,
});

const mapDispatchToProps = dispatch => ({
  hide: () => dispatch(hideModal()),
  showModal: (name, data) => dispatch(showModal(name, data)),
});

export default translate()(connect(
  mapDispatchToState,
  mapDispatchToProps,
)(withOverlayAction(ModalDashboardCreated)));
