/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import ReactGA from 'react-ga';
import { isStorybookOrTest } from '@nettoken/env';
import env from 'environment/env';

const getAnalyticsPropertyId = () => env.googleAnalyticsId;

/**
 * Set a global variable to optionally disable user tracking. We use this
 * to stop tracking all users as soon as they log into the dashboard.
 *
 * {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/user-opt-out}
 *
 * @param {boolean} allow Can we track the user using Google Analytics?
 */
const setAnalyticsTrackingPermission = allow => {
  const id = getAnalyticsPropertyId();
  const key = `ga-disable-${id}`;
  window[key] = !allow;
};

export const disableGoogleAnalytics = () => setAnalyticsTrackingPermission(false);
export const enableGoogleAnalytics = () => setAnalyticsTrackingPermission(true);

/**
 * Initialises Google Analytics. Must be called before any tracking
 * methods are called.
 */
export const setupGoogleAnalytics = () => {
  const id = getAnalyticsPropertyId();
  ReactGA.initialize(id);
  trackPageGoogleAnalytics();
};

/**
 * Records a page loaded event for the current location.
 */
export const trackPageGoogleAnalytics = () => {
  if (isStorybookOrTest) return;
  const { pathname, search } = window.location;
  const page = pathname + search;
  ReactGA.pageview(page);
};
