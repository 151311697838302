/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { PRESET_MOCK_SERVER } from 'constants/presets';
import { getPreset } from 'main/tools/reduxState';
import { store } from 'reducers/store';
import env from 'environment/env';
import { isTest } from 'utils/misc';

const Fetch = (url, options) => new Promise(resolve => import('./response')
  .then(exported => {
    const payload = options.body ? JSON.parse(options.body) : {};
    const res = exported.default(url, options.method, payload);
    return resolve(res);
  }));

export default (url, options) => {
  const mock = store.dispatch(getPreset(PRESET_MOCK_SERVER));
  const handler = mock || isTest() ? Fetch : fetch;
  if (!mock && !isTest() && !url.includes('://')) url = env.serverUrl + url;
  return handler(url, options);
};
