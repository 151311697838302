/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { EVENT_GROUP_CHANGED, SOURCE_WEB_APP } from 'constants/events';
import { List, Map } from 'immutable';

import {
  REGULAR_KEYS,
  addClass,
  addEvent,
  eventKey,
  eventTarget,
  findNode,
  getCrossBrowserEvent,
  getDocumentRelativeCoordinates,
  pointBelongsToRectangle,
  rectanglesIntersect,
  removeClass,
  removeEvent,
} from '@nettoken/helpers';

import Validator from '@nettoken/validator';
import { RXMoveId } from 'main/move/reduxActions';
import { moveCredentialInGroup, moveCredentialToGroupId, postActivityLogs } from 'main/vault/credentials';
import { getCredentialIndexInGroup, getGroupIdFromCredentialId } from 'main/vault/credentials/reduxState';
import { moveGroup } from 'main/vault/groups';
import { trackMoveEvents } from 'main/tracking';
import { countGroupCredentials, getUnsortedGroupId } from 'main/vault/groups/reduxState';
import { store } from 'reducers/store';
import CSSVars from 'environment/variables';
import GroupStyles from 'Group/style.css';
import GStyles from 'styles/index.css';
import IconStyles from 'Icon/style.css';
import { getOneCredential } from '../main/vault/credentials/reduxState';
import { createGroup } from '../main/vault/groups';
import { RXToasterShow } from '../main/modal/reduxActions';
import { showModal } from '../main/modal';
import { MODAL_DROP_ACCOUNT_CONFIRMATION } from '../constants/modal';

const config = {
  draggedScale: CSSVars.sMoveScale,
  speed: {
    fast: CSSVars.trMoveFast,
    normal: CSSVars.trMove,
  },
  startDelay: CSSVars.trMoveDelay,
};

const { dispatch, getState } = store;

// Used to identify unique resources regardless of their type.
export const MOVE_ATTRIBUTE = 'data-move-id';

// Distinguishes between interactive nodes of type icon and group.
export const MOVE_CLASS_GROUP = 'js-move-group';
export const MOVE_CLASS_ICON = 'js-move-icon';

// Used on DOM elements to signal they can be manipulated
// by Move.js.
export const MOVE_START_WORD = 'js-move-start';

// Trigger used to stop searching for draggable nodes.
// Basically an equivalent of event.stopPropagation()
// and opposite of `MOVE_START_WORD`.
export const MOVE_STOP_WORD = 'js-move-stop';

// Elements with this class pass through an extra check
// when evaluating whether we should start the interaction.
// This check assumes there is a visible scrollbar on the
// right edge of the element, and calculates whether we
// are not simply trying to drag this scrollbar instead
// of the whole node.
export const MOVE_CHECK_SCROLLBAR = 'js-move-scroll';

/**
 * @param  {...integer} args
 *
 * @returns {float}
 */
const avg = (...args) => args.reduce((a, b) => a + b) / args.length;

/**
 * @param {string} mode
 *
 * @returns {string}
 */
const getTriggerWord = mode => mode === 'icon' ? MOVE_CLASS_ICON : MOVE_CLASS_GROUP; // eslint-disable-line no-confusing-arrow

/**
 * Recursively try to climb up the DOM tree and return the first
 * node that matches the class name.
 *
 * @param {HTMLElement} element The starting element in the DOM tree.
 * @param {string} matchClass A namespace class for a group or an icon.
 *
 * @returns {HTMLElement} Null if not found.
 */
const detectNode = (element, matchClass) => {
  // Our stop function is null node or the app root node. We can also use
  // a special stop keyword to make only certain areas of elements trigger
  // the drag behaviour.
  if (!element || element.classList.contains(MOVE_STOP_WORD) || window.AppRoot === element) {
    return null;
  }

  // We found our match, let's return it. For groups we return the parent
  // wrapper to preserve the node scale in animation.
  if (element.classList.contains(matchClass)) {
    return matchClass === getTriggerWord('group') ? element.parentElement : element;
  }

  // No match found, let's climb up the DOM tree.
  return detectNode(element.parentElement, matchClass);
};

/**
 * @returns {object} References to all nodes in the view.
 * @property {array} groups
 * @property {array} icons
 */
const getAllNodesInViewReferences = () => {
  const unsortedId = dispatch(getUnsortedGroupId(true));
  // if (!unsortedId) {
  //   const { currentDashboard } = getState().ui;
  //   unsortedId = await dispatch(createGroup('unsorted', true, null, currentDashboard));
  // }
  return {
    groups: [
      findNode(`#${unsortedId}`, true),
      ...Array.from(findNode(`.${getTriggerWord('group')}`, true)),
    ],
    icons: Array.from(findNode(`.${getTriggerWord('icon')}`, true)),
    dashboards: Array.from(findNode('.js-move-dashboard', true)),
  };
};

/**
 * @returns {object}
 * @property {array} groups
 * @property {array} icons
 */
const getAllNodesInViewPositions = () => {
  let groups = new List();
  let groupsTree = new Map();
  let icons = new List();
  let dashboards = new List();
  const nodesInViewReferences = getAllNodesInViewReferences();

  // The order of these is important for it to work properly.
  ['groups', 'icons', 'dashboards'].forEach(key => {
    const isIcon = key === 'icons';
    const isGroup = key === 'groups';
    const nodes = nodesInViewReferences[key];

    for (let i = 0; i < nodes.length; i += 1) {
      const node = nodes[i];
      if (node) {
        const id = node.getAttribute(MOVE_ATTRIBUTE);
        const rect = getDocumentRelativeCoordinates(node);
        const item = {
          bottom: rect.bottom,
          id,
          left: rect.left,
          node,
          right: rect.right,
          top: rect.top,
        };

        if (isIcon) {
          const groupId = dispatch(getGroupIdFromCredentialId(item.id));
          const group = groupsTree.get(groupId);

          // Do not count hidden icons because the algorithm will incorrectly
          // think we are reordering them -> big mess.
          if (rectanglesIntersect(group, item)) {
            icons = icons.push(item);
          }
        }
        else if (isGroup) {
          groupsTree = groupsTree.set(item.id, item);
          groups = groups.push(item);
        }
        else {
          dashboards = dashboards.push(item);
        }
      }
    }
  });

  return {
    groups: groups.toArray(),
    icons: icons.toArray(),
    dashboards: dashboards.toArray(),
  };
};

/**
 * @param {string} mode
 *
 * @returns {object}
 */
const getStyles = mode => mode === 'icon' ? IconStyles : GroupStyles; // eslint-disable-line no-confusing-arrow

/**
 * Uses Pythagorean theorem to calculate distance between two points.
 *
 * @param {object} p1
 * @param {object} p2
 *
 * @returns {integer}
 */
const pointsDistance = (p1, p2) => {
  const a2 = (p2.x - p1.x) ** 2;
  const b2 = (p2.y - p1.y) ** 2;
  const c2 = a2 + b2;
  const d = Math.sqrt(c2);
  return d;
};

/**
 * @param {integer} top
 * @param {integer} right
 * @param {integer} bottom
 * @param {integer} left
 *
 * @returns {object} Rectangle center.
 */
const rectangleCenter = (top, right, bottom, left) => ({
  x: avg(left, right),
  y: avg(top, bottom),
});

/**
 * @param {string} draggedId Dragged element object id.
 * @param {array} nodes Iterable nodes array for the specified type.
 * @param {object} draggedNodeCenter Center coordinates of the dragged icon.
 * @param {string} mode
 * @param {object} cursor
 *
 * @returns {string}
 */
const getUnderlyingElementId = (draggedId, nodes, draggedNodeCenter, mode, cursor) => {
  let id = false;
  let dMin;

  for (let i = 0; i < nodes.length; i += 1) {
    const node = Object.assign({}, nodes[i]);

    // Checks the closest icon.
    if (mode === 'icon') {
      const nodeCenter = rectangleCenter(node.top, node.right, node.bottom, node.left);
      const d = pointsDistance(nodeCenter, draggedNodeCenter);

      // The iterated node is not our dragged node and is closer to the dragged node
      // than the previous minimum, or this is the first iteration.
      if (node.id !== draggedId && (dMin === undefined || d < dMin)) {
        dMin = d;
        ({ id } = node);
      }
    }
    else {
      // Dragging within the group wrapper's margin still counts as dragging
      // within the group. This is because high sensitivity did not provide good
      // user experience, the icon would constantly flicker to the unsorted group
      // as soon as we left the group.
      if (mode === 'group') {
        const groupWrapperCSS = getComputedStyle(node.node.parentElement);
        node.top -= Validator.strToInt(groupWrapperCSS.marginTop);
        node.right += Validator.strToInt(groupWrapperCSS.marginRight);
        node.bottom += Validator.strToInt(groupWrapperCSS.marginBottom);
        node.left -= Validator.strToInt(groupWrapperCSS.marginLeft);
      }

      if (pointBelongsToRectangle(cursor, node)) {
        const { id: nodeId } = node;
        return !nodeId || nodeId === 'null' ? null : nodeId;
      }
    }
  }

  return id;
};

/**
 * Moves the passed node by setting on it the supplied transform property.
 *
 * @param {HTMLElement} node
 * @param {string} [gear]
 * @param {string} [transform='']
 */
const moveNode = (node, gear, transform = '') => {
  switch (gear) {
    case 'fast':
    case 'normal':
      node.style.transition = `transform ${config.speed[gear]}ms`;
      break;

    case 'none':
    default:
      node.style.transition = '';
      break;
  }

  node.style.transform = transform;
};

/**
 * Reverse method for `preventUserFromSelectingWhileDragging()`.
 */
const allowUserToSelectWhileDragging = () => {
  removeClass(document.body, GStyles.unselectable);
};

/**
 * Stops us from selecting content while dragging across the screen.
 *
 * @param {object} event
 */
const preventUserFromSelectingWhileDragging = event => {
  event.preventDefault();
  addClass(document.body, GStyles.unselectable);
};

const unselectSelection = () => {
  window.getSelection().removeAllRanges();
};

class Move {
  constructor() {
    this.state = this.getDefaultState();

    this.throttleCursorMove = null;
    this.timerEndDelay = null;
    this.timerRenderState = null;
    this.timerStartDelay = null;

    this.isIconGroupChanged = this.isIconGroupChanged.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.performMouseUpCallback = this.performMouseUpCallback.bind(this);
    this.handleDropEvent = this.handleDropEvent.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDroppedEvent = this.handleDroppedEvent.bind(this);
    this.handleLeaveEvent = this.handleLeaveEvent.bind(this);

    this.addDownCallback();
    this.addUpCallback();
  }

  handleDropEvent() {
  }

  handleDragLeave() {
  }

  handleDroppedEvent() {
  }

  handleLeaveEvent(event) {
    if (this.state.id) {
      if (this.state.mode != 'icon') {
        this.setState({ pushToServer: true });
        this.updateState(event, true);
      }
      else {
        const { mode, node, nodeHidden } = this.state;
        const { hidden } = getStyles(mode);
        node.remove();
        removeClass(nodeHidden, hidden);
      }
    }
  }

  addCursorCallbacks() {
    this.addLeaveCallback();
    this.addMoveCallback();
  }

  addDownCallback() {
    addEvent(document.body, 'touchstart', this.handleMouseDown);
    addEvent(document.body, 'mousedown', this.handleMouseDown);
  }

  addLeaveCallback() {
    addEvent(document, 'mouseout', this.handleMouseLeave);
  }

  addMoveCallback() {
    addEvent(document.body, 'mousemove', this.handleMouseMove);
    addEvent(document.body, 'touchmove', this.handleMouseMove);
  }

  addUpCallback() {
    addEvent(document.body, 'touchend', this.handleMouseUp);
    addEvent(document.body, 'mouseup', this.handleMouseUp);
    addEvent(document.body, 'touchend', this.handleDroppedEvent);
    addEvent(document.body, 'mouseup', this.handleLeaveEvent);
  }

  animateNode(gear, tr) {
    requestAnimationFrame(() => moveNode(this.state.node, gear, tr));
  }

  /**
   * Animates the dragged node back to the origin position (also destination).
   *
   *
   * @param {HTMLElement} node
   * @param {object} origin Destination coordinates.
   */
  animateNodeToOrigin(node, origin) {
    const rect = getDocumentRelativeCoordinates(node);
    const left = rect.left - origin.left;
    const top = rect.top - origin.top;
    this.animateNode('normal', `translateX(${left}px) translateY(${top}px) scale(1)`);
  }

  clearEndDelay() {
    clearTimeout(this.timerEndDelay);
    this.timerEndDelay = null;
  }

  clearRenderState() {
    clearTimeout(this.timerRenderState);
    this.timerRenderState = null;
  }

  clearStartDelay() {
    clearTimeout(this.timerStartDelay);
    this.timerStartDelay = null;
  }

  /**
   * Checks if we are dragging an icon or a group.
   *
   * @param {object} event
   *
   * @returns {boolean} False if the click-and-hold was on other element.
   */
  detectModeAndNode(event) {
    const target = eventTarget(event);

    let mode = 'icon';
    let node = detectNode(target, getTriggerWord(mode));

    if (!node) {
      mode = 'group';
      node = detectNode(target, getTriggerWord(mode));
    }

    if (!node) return false;

    this.setState({ mode, node });
    return true;
  }

  getDefaultState() {
    return {
      // Animation lock. While we're animating transitions certain
      // actions have to wait.
      animating: null,
      // Refers to the group currently located below our cursor.
      groupBelowId: null,
      dashboardId: null,
      // Arrays with position information about all groups and
      // icons in the view. Both use the same schema.
      //
      // Shape:
      // {
      //   bottom: 422,
      //   id: 'temp_7v0PBF',
      //   left: 98,
      //   node: <DOM>,
      //   right: 422,
      //   top: 297,
      // }
      groups: null,
      icons: null,
      dashboards: null,
      // Currently dragged node object id. This can be retrieved by querying
      // the actual DOM element.
      id: null,
      initialGroupId: null,
      // We apply different behaviour based on if we drag a `group` or an `icon`.
      mode: null,
      // Reference to the currently dragged element. This is at first the clicked
      // element, but then we quickly swap it for a copy we can drag around the
      // document.
      node: null,
      // A hidden original of the dragged element. This variable will be set
      // only after we injected a copy into the document. We then hide the
      // original as it still needs to occupy its space and we will reveal it
      // at the end of the interaction.
      nodeHidden: null,
      // Group id for the initial group. This can be either the group we
      // are dragging, or the group our icon belonged to at the beginning.
      originGroupId: null,
      // Used to calculate how far we moved since we started dragging.
      pointerOriginPosition: null,
      positionStartNode: null,
      pushToServer: false,
    };
  }

  /**
   * Return the new icon insert index in its new group.
   *
   * @param {integer} closestIndex
   * @param {integer} appIndexInGroup
   * @param {boolean} overlapsClosest
   * @param {string} iconBelowId False if none.
   *
   * @returns {integer}
   */
  getIconInsertIndex(closestIndex, appIndexInGroup, overlapsClosest, iconBelowId) {
    const { groupBelowId } = this.state;
    let insertIndex = closestIndex;

    if (this.state.originGroupId === groupBelowId && iconBelowId === false) {
      const items = dispatch(countGroupCredentials(groupBelowId));

      // Icon is not at the last index yet.
      insertIndex = items !== appIndexInGroup + 1 ? items : appIndexInGroup;
    }
    else if (!overlapsClosest) {
      insertIndex = appIndexInGroup;
    }

    if (Number.isNaN(insertIndex)) {
      insertIndex = 0;
    }

    return insertIndex;
  }

  getNodeArray(type) {
    let nodes;
    switch (type) {
      case 'group':
        nodes = this.state.groups;
        break;
      case 'icon--precise':
      case 'icons':
        nodes = this.state.icons;
        break;
      case 'dashboards':
        nodes = this.state.dashboards;
        break;
      default:
        nodes = this.state.icons;
        break;
    }
    return nodes;
  }

  /**
   * Returns the id of node below cursor. Alternatively, we can return the
   * closest node.
   *
   * @param {string} type oneOf(['group', 'icon', 'icon--precise', 'dashboards'])
   * @param {object} event
   *
   * @returns {string}
   */
  getNodeBelowId(type, event) {
    const coords = getDocumentRelativeCoordinates(this.state.node);
    const nodeCenter = rectangleCenter(coords.top, coords.right, coords.bottom, coords.left);
    const nodes = this.getNodeArray(type);
    const cursor = {
      x: event.pageX,
      y: event.pageY,
    };
    const id = getUnderlyingElementId(this.state.id, nodes, nodeCenter, type, cursor);
    return id;
  }

  handleMouseDown(event) {
    event = getCrossBrowserEvent(event);

    if (!this.isDragAvailable(event)) return;

    // Ensure this is not a regular click.
    this.timerStartDelay = setTimeout(() => requestAnimationFrame(() => {
      this.clearStartDelay();

      if (this.detectModeAndNode(event)) {
        this.performMouseDownCallback(event);
      }
    }), config.startDelay);
  }

  /**
   * Calls the final `handleMouseUp()` method if we left the window
   * with our cursor. This is to avoid weird bugs,
   *
   * @param {object} event
   */
  handleMouseLeave(event) {
    event = getCrossBrowserEvent(event);
    const origin = event.relatedTarget || event.toElement;
    if (!origin || origin.nodeName === 'HTML') {
      this.handleMouseUp(event);
    }
  }

  handleMouseMove(event) {
    clearTimeout(this.throttleCursorMove);
    this.throttleCursorMove = setTimeout(() => {
      this.throttleCursorMove = null;

      const { pointerOriginPosition } = this.state;
      event = getCrossBrowserEvent(event);
      const left = event.pageX - pointerOriginPosition.left;
      const top = event.pageY - pointerOriginPosition.top;
      this.animateNode('fast', `translateX(${left}px) translateY(${top}px) scale(${config.draggedScale})`);
      this.updateState(event, false);
    }, 10);
  }

  handleMouseUp(event) {
    event = getCrossBrowserEvent(event);
    allowUserToSelectWhileDragging();
    this.clearStartDelay();

    // We released mouse before we started dragging. This means we probably
    // just opened the app with a single click.
    if (!this.state.id) return;

    this.removeLeaveCallback();
    this.removeMoveCallback();
    this.updateState(event, true);
    requestAnimationFrame(() => this.performMouseUpCallback());
  }

  /**
   * Copies the node we want to drag, stores the old node, replaces
   * the pointer to the copied node, and inserts it into the document
   * at the same position as old node so it appears to be still the
   * same old node.
   */
  hideOriginalNodeAndInjectCopy() {
    const { mode, node } = this.state;
    const { dragged, hidden } = getStyles(mode);

    const rect = getDocumentRelativeCoordinates(node);
    const copy = node.cloneNode(true);

    addClass(copy, dragged);
    removeClass(copy, getTriggerWord(mode));
    this.updateNodeAbsolutePosition(rect, copy);
    copy.style.position = 'absolute';
    copy.style.zIndex = 9999;

    // Hide the old copy and replace with new copy.
    addClass(node, hidden);
    this.setState({ node: copy, nodeHidden: node });
    document.body.appendChild(copy);

    // Restore scroll level to make the interaction appear smoother.
    if (mode === 'group') {
      const [scrollableDiv] = node.getElementsByClassName(GroupStyles.group);
      const [copyScrollableDiv] = copy.getElementsByClassName(GroupStyles.group);
      copyScrollableDiv.scrollTop = scrollableDiv.scrollTop;
    }
  }

  /**
   * We cannot use Move.js while holding special keys (Shift, Alt, Ctrl, ⌘, RMB)
   * or if the timer is running.
   *
   * @returns {boolean}
   */
  isDragAvailable(event) {
    return !(event.ctrlKey || event.metaKey || event.altKey || event.shiftKey ||
      eventKey(event) === REGULAR_KEYS.RIGHT_MOUSE_BUTTON || this.state.animating ||
      this.timerRenderState || this.isScrollbarDrag(event));
  }

  isIconGroupChanged() {
    if (this.state.mode === 'icon' && this.state.initialGroupId !== this.state.groupBelowId) {
      return true;
    }
    return false;
  }

  isRenderAvailable() {
    return !this.state.animating;
  }

  /**
   * Check if we are not just trying to drag the scrollbar
   * as opposed to lifting the whole group (only groups have
   * scrollbars).
   *
   * @returns {boolean}
   */
  isScrollbarDrag(event) {
    const target = eventTarget(event);
    const { pageX: x } = event;
    if (!target.classList.contains(MOVE_CHECK_SCROLLBAR)) return false;
    const { right } = getDocumentRelativeCoordinates(target);
    return x <= right && x >= right - CSSVars.sGroupScrollbar;
  }

  /**
   * We cannot update state if it's already being updated or if we are in
   * the group mode and the underlying node is the same as the dragged one.
   *
   * @returns {boolean}
   */
  isUpdateAvailable() {
    const {
      groupBelowId,
      id,
      mode,
    } = this.state;
    return (this.isRenderAvailable() && (mode !== 'group' || (groupBelowId && groupBelowId !== id)));
  }

  performMouseDownCallback(event) {
    preventUserFromSelectingWhileDragging(event);
    unselectSelection();
    this.setDraggedId();
    this.hideOriginalNodeAndInjectCopy();
    this.addCursorCallbacks();

    this.setPointerPosition(event.pageX, event.pageY);
    this.setNodePositions();

    const originGroupId = this.getNodeBelowId('group', event);
    this.setState({ initialGroupId: originGroupId, originGroupId });

    const rect = getDocumentRelativeCoordinates(this.state.node);
    this.setStartNodePosition(rect.left, rect.top);

    this.animateNode('normal', `scale(${config.draggedScale})`);
  }

  performMouseUpCallback() {
    this.clearEndDelay();
    const { dragged } = getStyles(this.state.mode);
    const delay = this.state.animating ? config.speed.normal : 0;

    // We need to wait for the animation to finish here. This is because
    // as the nodes swap positions, until the animation isn't finished,
    // our hidden node is moving. Therefore we would drop the icon back
    // into whatever position the hidden node is currently in. Sometimes
    // this would appear like the node is being droppped onto the other
    // node we were supposed to swap positions with. The only way to
    // ensure the animation looks acceptable is to wait until the timeout
    // passed. In the future we could use FLIP to infer the final
    // position before the node actually reaches it.
    this.timerEndDelay = setTimeout(() => requestAnimationFrame(() => {
      this.animateNodeToOrigin(this.state.nodeHidden, this.state.positionStartNode);
      this.setPointerPosition();
      removeClass(this.state.node, dragged);

      // Wait for the animation to finish again.
      this.timerEndDelay = setTimeout(() => {
        this.clearEndDelay();
        this.showOriginalNodeAndDeleteCopy();
        this.setState(this.getDefaultState());
        dispatch(RXMoveId());
      }, config.speed.normal);
    }), delay);

    if (this.isIconGroupChanged()) {
      if (getState().preferences.tracking) {
        // let toGroup = this.state.groupBelowId;
        // if (this.state.groupBelowId === false && this.state.mode === 'icon') {
        //   toGroup = dispatch(getUnsortedGroupId());
        // }
        // dispatch(trackMoveEvents({
        //   fromGroup: this.state.initialGroupId,
        //   toGroup,
        //   credentialIds: [this.state.id],
        // }));
      }
    }
  }

  removeLeaveCallback() {
    removeEvent(document, 'mouseout', this.handleMouseLeave);
  }

  removeMoveCallback() {
    removeEvent(document.body, 'mousemove', this.handleMouseMove);
    removeEvent(document.body, 'touchmove', this.handleMouseMove);
  }

  /**
   * Updates the visual state representation.
   */
  renderState() {
    if (!this.isRenderAvailable()) return;
    this.clearRenderState();
    this.setState({ animating: true });

    // Save previous group below id here so we don't lose it while
    // we wait for the animation to finish below.
    const { groupBelowId } = this.state;

    this.updateHiddenNodeReference();
    this.setState({ pushToServer: false });

    this.timerRenderState = setTimeout(() => {
      this.clearRenderState();
      this.setNodePositions();
      this.setState({ originGroupId: groupBelowId });

      /*
       * Prevents from triggering an edge case where user could release
       * an icon to start the animation, but then quickly attempt to start
       * another animation while the previous hasn't finished.
       */
      if (!this.timerEndDelay) {
        this.setState({ animating: false });
      }
    }, config.speed.normal);
  }

  /**
   * Updates the state id with the dragged node id. We went one level up
   * when detecting node for groups, so we need to grab the first child
   * here to reverse that side-effect.
   */
  setDraggedId() {
    const { mode, node } = this.state;
    const element = mode === 'icon' ? node : node.firstChild;
    const id = element.getAttribute(MOVE_ATTRIBUTE);
    this.setState({ id });
    dispatch(RXMoveId(id));
  }

  /**
   * Sets the state for group and icon nodes.
   */
  setNodePositions() {
    const { groups, icons, dashboards } = getAllNodesInViewPositions();
    this.setState({ groups, icons, dashboards });
  }

  setPointerPosition(left = 0, top = 0) {
    const pointerOriginPosition = { left, top };
    this.setState({ pointerOriginPosition });
  }

  /**
   * Also used to calculate how far we've moved since we started dragging.
   * The difference is that the cursor is a single point, but nodes are
   * calculated from their edges. So we store the value of top left edge
   * since 99.99% of clicks will be elsewhere within the node. We could also
   * calculate the value on the fly based on the node width, but this is easier.
   *
   * @param {integer} [left=0]
   * @param {integer} [top=0]
   */
  setStartNodePosition(left = 0, top = 0) {
    const positionStartNode = { left, top };
    this.setState({ positionStartNode });
  }

  setState(nextState = {}) {
    this.state = Object.assign(this.state, nextState);
  }

  /**
   * Reverse of `hideOriginalNodeAndInjectCopy()`.
   */
  showOriginalNodeAndDeleteCopy() {
    const { mode, node, nodeHidden } = this.state;
    const { hidden } = getStyles(mode);
    removeClass(nodeHidden, hidden);

    // Restore scroll level to make the interaction appear smoother.
    if (mode === 'group') {
      const [copyScrollableDiv] = node.getElementsByClassName(GroupStyles.group);
      const [scrollableDiv] = nodeHidden.getElementsByClassName(GroupStyles.group);
      scrollableDiv.scrollTop = copyScrollableDiv.scrollTop;
    }

    node.remove();
  }

  /**
   * We need to do this because the existing reference becomes disconnected
   * when we move it in the DOM tree.
   */
  updateHiddenNodeReference() {
    const { mode } = this.state;
    const nodes = getAllNodesInViewReferences()[`${mode}s`];
    for (let i = 0; i < nodes.length; i += 1) {
      if (nodes[i] && nodes[i].getAttribute(MOVE_ATTRIBUTE) === this.state.id) {
        const nodeHidden = (mode === 'group') ? nodes[i].parentElement : nodes[i];
        this.setState({ nodeHidden });
        break;
      }
    }
  }

  /**
   * @param {object} rect
   * @param {HTMLElement} node
   */
  updateNodeAbsolutePosition(rect, node) {
    const computedCSS = getComputedStyle(this.state.node);
    const marginLeft = Validator.strToInt(computedCSS['margin-left']);
    const marginTop = Validator.strToInt(computedCSS['margin-top']);
    node.style.left = `${rect.left - marginLeft}px`;
    node.style.top = `${rect.top - marginTop}px`;
  }

  /**
   * Updates the state representation in code.
   *
   * @param {object} event
   */
  updateState(event, serverPush) {
    const groupBelowId = this.getNodeBelowId('group', event);
    this.setState({ groupBelowId });
    let dashboardId = null;
    if (!groupBelowId) {
      dashboardId = this.getNodeBelowId('dashboards', event);
      this.setState({ dashboardId });
    }
    if (serverPush == false && this.state.pushToServer != true && !this.isUpdateAvailable()) return;
    if (this.state.mode === 'icon') {
      this.updateStateIcon(event, serverPush);
      return;
    }

    this.updateStateGroup();
  }

  /**
   * Move the node in JavaScript state tree. Re-render state
   * only if the state changed.
   */
  updateStateGroup() {
    const { groupBelowId, id, pushToServer } = this.state;
    const insertIndex = getState().groupsOrder.order.indexOf(groupBelowId);
    dispatch(moveGroup(id, insertIndex, pushToServer));
    // requestAnimationFrame(this.performMouseUpCallback);
    requestAnimationFrame(() => this.renderState());
  }

  updateStateIcon(event, serverPush) {
    const { groupBelowId, id, dashboardId } = this.state;

    if (serverPush && !groupBelowId && dashboardId) {
      const credential = dispatch(getOneCredential(id));
      dispatch(showModal(MODAL_DROP_ACCOUNT_CONFIRMATION, {
        data: credential,
        fromDashbard: getState().ui.currentDashboard,
        toDashboard: dashboardId == 'home' ? null : dashboardId,
      }));
      requestAnimationFrame(() => this.renderState());
    }
    else {
      let closestIconId;
      let closestIndex;
      let iconBelowId;
      let overlapsClosest;
      if (groupBelowId !== false) {
        closestIconId = this.getNodeBelowId('icon', event);
        closestIndex = dispatch(getCredentialIndexInGroup(closestIconId));
        iconBelowId = this.getNodeBelowId('icon--precise', event);
        overlapsClosest = closestIconId === iconBelowId;
      }
      else if (!this.state.originGroupId) {
        return;
      }

      const appIndexInGroup = dispatch(getCredentialIndexInGroup(id));
      let insertIndex = this.getIconInsertIndex(
        closestIndex,
        appIndexInGroup,
        overlapsClosest,
        iconBelowId,
      );

      // Move the node in JavaScript state tree. Re-render state
      // only if the state changed.
      if (this.state.originGroupId !== groupBelowId || serverPush == true ||
        this.state.pushToServer) {
        let nextGroupId = groupBelowId;

        // We dragged the icon into an empty space outside the groups,
        // put it as the first icon inside the unsorted group.
        if (iconBelowId === undefined) {
          nextGroupId = dispatch(getUnsortedGroupId());
          insertIndex = 0;
        }
        // We dragged the icon into an empty space within the group,
        // default position is the last icon within this group.
        else if (iconBelowId === false) {
          insertIndex = dispatch(countGroupCredentials(nextGroupId));
        }
        const credential = dispatch(getOneCredential(id));
        credential.groupId = nextGroupId;
        dispatch(moveCredentialToGroupId({
          credentialId: id,
          groupId: nextGroupId,
          insertIndex,
          credential,
          fromMoveInput: true,
          pushToServer: serverPush || this.state.pushToServer,
        }));
        if (serverPush || this.state.pushToServer) {
          if (this.state.nodeHidden) {
            const { mode, node, nodeHidden } = this.state;
            const { hidden } = getStyles(mode);
            const element = document.querySelector(`[data-move-id="${nodeHidden.getAttribute('data-move-id')}"]`);
            if (element) {
              element.classList.remove(hidden);
            }
          }
          // requestAnimationFrame(this.performMouseUpCallback);
          // dispatch(RXToasterShow({
          //   status: 'open',
          //   type: 'success',
          //   value: 'Changes saved!',
          // }));
        }
        requestAnimationFrame(() => this.renderState());
      }
      else if (appIndexInGroup !== insertIndex && iconBelowId !== id) {
        dispatch(moveCredentialInGroup(id, insertIndex));
        requestAnimationFrame(() => this.renderState());
      }
    }
  }
}

export const move = new Move();
