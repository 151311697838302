/*
 * Copyright (C) 2018 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { addClass, removeClass } from '@nettoken/helpers';
import colours from 'environment/colours';
import CSSVars from 'environment/variables';
import Styles from './style.css';

class CounterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.animating = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.count === prevProps.count) return;
    this.animateIcon(this.props.count > prevProps.count ? 'inc' : 'dec');
  }

  animateIcon(direction) {
    const { icon, label } = this.refs;
    if (!icon || !label || this.animating) return;

    const className = direction === 'inc' ? Styles.animatedInc : Styles.animatedDec;

    // Start the animation.
    this.animating = true;
    addClass(icon, className);
    addClass(label, className);

    // Wait for the animation to finish.
    setTimeout(() => {
      removeClass(icon, className);
      removeClass(label, className);
      this.animating = false;
    }, CSSVars.trCounter);
  }

  render() {
    return (
      <div className={classNames({
        [Styles.wrapper]: true,
        [this.props.className]: this.props.className,
        [Styles.MarginLeft]: true,
      })}
      >
        <div
          className={Styles.counter}
          style={{
            height: this.props.size,
            width: this.props.size,
          }}>
          <svg
            className={Styles.icon}
            height={this.props.size}
            ref="icon"
            xmlns="http://www.w3.org/2000/svg"
            width={this.props.size}>
            <g
              fill="none"
              fillRule="evenodd">
              <circle
                cx={this.props.size / 2}
                cy={this.props.size / 2}
                r={(this.props.size / 2) - 2}
                stroke={colours.cPrimary}
                strokeWidth="2"
                 />
            </g>
          </svg>

          <span
            className={classNames({
              [Styles.bar]: this.props.noGreyBar === true,
              [Styles.bar1]: this.props.noGreyBar === undefined,
              [Styles.rotation]: this.props.rotation,
            })}
           />

          <span
            className={classNames({
              [Styles.counterLabel]: true,
              [Styles[`_${this.props.colour}`]]: true,
              [Styles[`_${this.props.font}`]]: true,
              [Styles.counterSmallFont]: (
                !this.props.undocked &&
                this.props.count > 99 && this.props.count <= 999
              ),
              [Styles.counterExtraSmallFont]: !this.props.undocked && this.props.count > 999,
            })}
            ref="label"
            dangerouslySetInnerHTML={{ __html: this.props.count }} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.label && <span className={classNames({
            [Styles.label]: 1,
            [Styles.withPadding]: this.props.isfocus === true,
          })}>
            {this.props.label}
          </span>}
        </div>
      </div>
    );
  }
}

CounterContainer.defaultProps = {
  background: colours.cBg,
  className: '',
  colour: 'cWhite',
  count: 0,
  font: 'lg',
  label: '',
  size: 84,
  rotation: false,
};

CounterContainer.propTypes = {
  background: PropTypes.string,
  className: PropTypes.string,
  colour: PropTypes.string,
  count: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  label: PropTypes.string,
  size: PropTypes.number,
  rotation: PropTypes.bool,
};

export default CounterContainer;
