/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import { URL_BACKUP } from 'constants/endpoints';
import { MASTER_KEY_BACKUP_EXT } from 'constants/misc';
import React from 'react';
import ReactDOM from 'react-dom';
import { List } from 'immutable';

import {
  CW_CONVERT_INPUT,
  CW_DECRYPT_WITH_EXTERNAL_KEYS,
  CW_DERIVE_PUBLIC_KEY_FROM_SECRET,
  CW_EXTRACT_MASTER_KEYS,
  CW_GENERATE_EPHEMERAL_KEY_PAIR,
  getWorkerPromise,
} from '@nettoken/crypto-worker';

import { encodeQR, QR, QR_EVENT_BACKUP } from '@nettoken/react-qr';
import Validator from '@nettoken/validator';
import { RXUIGuideBackup } from 'main/ui/reduxActions';
import colours from 'environment/colours';
import { Request } from 'utils/request';
import srcLogo from 'images/logo--full.png';
import { RXBackupMasterKey } from './reduxActions';

const BACKUP_CHUNK_SIZE = 4;
const BACKUP_CHUNK_HEX_SIZE = 8;
const BACKUP_VERSION = '1.0';

/**
 * Send a request to the server with new backup data.
 *
 * @param {string} ciphertext
 * @param {array} quotient
 */
const backupPushToServer = (ciphertext, quotient) => {
  const req = new Request(URL_BACKUP);
  return req.authorise().put({ version: BACKUP_VERSION, ciphertext, quotient })
    .then(() => Promise.resolve())
    .catch(e => Promise.reject(e));
};

/**
 * @param {array} bigEndianIntegers
 *
 * @returns {UInt8Array}
 */
const bigEndianArrayToIntegerArray = async bigEndianIntegers => {
  try {
    const worker = getWorkerPromise('crypto');
    let promises = new List();

    for (let i = 0; i < bigEndianIntegers.length; i += 1) {
      const input = bigEndianIntegers[i].toString(16).padStart(BACKUP_CHUNK_HEX_SIZE, 0);
      const promise = worker({
        event: CW_CONVERT_INPUT,
        input,
        inputFormat: 'hex',
        outputFormat: 'bytes',
      })
        .then(({ output }) => Promise.resolve(output))
        .catch(e => Promise.reject(e));
      promises = promises.push(promise);
    }

    const reducer = (acc, value) => acc.push(...value);
    const integers = await Promise.all(promises.toArray());
    const integersList = integers.reduce(reducer, new List());
    const secretKey = Uint8Array.from(integersList.toArray());
    return secretKey;
  }
  catch (e) {
    throw e;
  }
};

/**
 * Returns x-coordinate that should be used when positioning this element inside
 * a PDF document to ensure it is centred.
 *
 * @param {integer} elWidth
 * @param {integer} pageWidth PDF page width in mm. Default is A4 width, 210mm.
 *
 * @returns {integer} Offset from the left edge on the page, in mm.
 */
const centerElementInPDF = (elWidth, pageWidth = 210) => (pageWidth - elWidth) / 2;

/**
 * Designs the backup PDF and shows the save dialog on the user device or
 * attempts to save the file automatically.
 *
 * @param {List} codes List of integers the user can use to restore their backup.
 * @param {string} phone User phone number.
 * @param {function} t i18next translate method.
 */
const createAndSaveBackupPDF = async (codes, phone, t) => new Promise((resolve, reject) => {
  const _32digitCode = codes.map(int => int.toString().padStart(BACKUP_CHUNK_SIZE, 0)).join(' ');
  const value = encodeQR(QR_EVENT_BACKUP, { key: codes.toArray(), version: BACKUP_VERSION });

  return getQRCodeValueAsBase64String(value, qr => createBackupPDFDesign({
    code: _32digitCode,
    phone,
    qr,
    t,
  })
    .then(file => {
      const date = new Date().toLocaleString('en-gb', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });
      file.save(`${t('views.backup.filename', { date })}.${MASTER_KEY_BACKUP_EXT}`);
      return resolve();
    })
    .catch(e => reject(e)));
});

/**
 * Designs the PDF we can save on the user's device.
 *
 * @param {object} args
 * @property {string} code Readable version of the generated backup code.
 * @property {string} phone User phone number.
 * @property {string} qr Inlined QR code we can use as image src value.
 * @property {function} t i18next translate method.
 *
 * @returns {object} Created PDF we can save with `save()`.
 */
const createBackupPDFDesign = args => new Promise(resolve => import('jspdf').then(JSPDF => {
  const { t } = args;
  const doc = new JSPDF();

  const logo = {
    height: 12,
    // Dimensions are obtained from the real logo icon in assets.
    ratio: Math.floor(414 / 69),
    src: srcLogo,
    x: 8,
    y: 8,
  };
  logo.width = logo.height * logo.ratio;

  const qr = {
    marginBottom: 8,
    marginTop: 16,
    size: 120,
  };
  qr.y = logo.y + logo.height + qr.marginTop;

  const note = {
    fontSize: 16,
    text: `${t('views.backup.generatedFor')} ${args.phone}`,
  };
  note.y = yText(note.fontSize, qr.y + qr.size + qr.marginBottom);

  const code = {
    fontSize: note.fontSize,
    text: `${t('views.backup.code')}: ${args.code}`,
  };
  code.y = yText(code.fontSize, note.y + 8);

  const copyright = {
    fontSize: 12,
    text: `\u00A9 ${new Date().getUTCFullYear()} ${t('legal.nameShort')}`,
    y: 290,
  };

  doc
    .setTextColor(colours.cPrimary)
    .addImage(logo.src, 'PNG', logo.x, logo.y, logo.width, logo.height)
    .addImage(args.qr, 'PNG', centerElementInPDF(qr.size), qr.y, qr.size, qr.size)
    .setFontSize(note.fontSize)
    .text(note.text, centerElementInPDF(0), note.y, 'center')
    .text(code.text, centerElementInPDF(0), code.y, 'center')
    .setFontSize(copyright.fontSize)
    .text(copyright.text, centerElementInPDF(0), copyright.y, 'center');

  return resolve(doc);
}));

/**
 * @param {string} encryptedSecretKey Base64 encoded Master Key secret key.
 * @param {string} publicKey Base64 encoded ephemeral public key.
 * @param {string} secretKey Base64 encoded ephemeral secret key.
 *
 * @returns {object}
 * @property {string} publicKey Base64 encoded Master Key public key.
 * @property {string} secretKey Base64 encoded Master Key secret key.
 */
const decryptMasterKey = async (encryptedSecretKey, publicKey, secretKey) => {
  try {
    const worker = getWorkerPromise('crypto');
    const { message: masterKeySecretKeyBytes } = await worker({
      event: CW_DECRYPT_WITH_EXTERNAL_KEYS,
      encrypted: encryptedSecretKey,
      format: 'bytes',
      publicKey,
      secretKey,
    });
    const { output: masterKeySecretKey } = await worker({
      event: CW_CONVERT_INPUT,
      input: masterKeySecretKeyBytes,
      inputFormat: 'bytes',
      outputFormat: 'base64',
    });
    const { publicKey: masterKeyPublicKey } = await worker({
      event: CW_DERIVE_PUBLIC_KEY_FROM_SECRET,
      secretKey: masterKeySecretKey,
    });
    return {
      publicKey: masterKeyPublicKey,
      secretKey: masterKeySecretKey,
    };
  }
  catch (e) {
    throw e;
  }
};

/**
 * @param {UInt8Array} secretKeyBytes
 *
 * @returns {object}
 * @property {string} publicKey Base64 encoded public key from the secret key.
 * @property {string} secretKey Base64 encoded secret key.
 */
export const derivePublicKeyFromSecretKey = async secretKeyBytes => {
  try {
    const worker = getWorkerPromise('crypto');
    const { output: secretKey } = await worker({
      event: CW_CONVERT_INPUT,
      input: secretKeyBytes,
      inputFormat: 'bytes',
      outputFormat: 'base64',
    });
    const { publicKey } = await worker({ event: CW_DERIVE_PUBLIC_KEY_FROM_SECRET, secretKey });
    return { publicKey, secretKey };
  }
  catch (e) {
    throw e;
  }
};

/**
 * Retrieves the current backup value from the server.
 *
 * @param {string} token Access token.
 *
 * @returns {object} backup
 */
const getBackup = token => {
  const req = new Request(URL_BACKUP);
  return req.authorise(token).get()
    .then(res => Promise.resolve(res.backup))
    .catch(e => Promise.reject(e));
};

/**
 * Returns the QR code encoded into a bse64 string via callback. This is done by
 * rendering an invisible QR code and immediately removing it after we have read
 * its data.
 *
 * @param {string} value Text to encode inside the QR code.
 * @param {function} onLoad Callback to execute when we encoded value into the QR code.
 */
const getQRCodeValueAsBase64String = (value, onLoad) => {
  class HiddenComponent extends React.Component {
    componentDidMount() {
      const wrapper = this.props.parent;
      const [canvas] = wrapper.getElementsByTagName('canvas');
      if (canvas) {
        const inlineBase64ImagePNG = canvas.toDataURL();
        onLoad(inlineBase64ImagePNG);
        wrapper.remove();
      }
    }

    render() {
      return (
        <QR value={value} />
      );
    }
  }

  renderHiddenComponent(HiddenComponent);
};

/**
 * Turns an array of integers into an array of big endian integers calculated
 * from the chunks of selected size.
 *
 * @param {array} integers
 * @param {integer} [chunkSize=BACKUP_CHUNK_SIZE] Size of chunks for the big endian integers.
 *
 * @returns {array}
 */
const integerArrayToBigEndianArray = (integers, chunkSize = BACKUP_CHUNK_SIZE) => {
  const worker = getWorkerPromise('crypto');
  const chunksTarget = Math.ceil(integers.length / chunkSize);
  let promises = new List();

  for (let i = 0; i < chunksTarget; i += 1) {
    const start = i * chunkSize;
    const chunk = integers.slice(start, start + chunkSize);
    const promise = worker({ event: CW_CONVERT_INPUT, input: chunk, outputFormat: 'hex' })
      .then(({ output }) => Promise.resolve(Number.parseInt(output, 16)))
      .catch(e => Promise.reject(e));
    promises = promises.push(promise);
  }

  return Promise.all(promises.toArray());
};

/**
 * Renders an invisible component in the view. This is so we can extract
 * data from the component which is otherwise not accessible. For example,
 * when we need to convert the QR Code into base64 data string by reading
 * its canvas value.
 *
 * @param {React.Component} RenderHidden
 */
const renderHiddenComponent = RenderHidden => {
  const wrapper = document.createElement('div');
  wrapper.style.display = 'none';
  document.body.appendChild(wrapper);
  setTimeout(() => ReactDOM.render(<RenderHidden parent={wrapper} />, wrapper), 0);
};

/**
 * Turns an array of integers into an array of quotients and remainders after
 * dividing each integer with the passed divisor.
 *
 * @param {array} integers
 * @param {integer} [divisor] Number to divide by.
 *
 * @returns {object}
 * @property {List} quotients
 * @property {List} remainders
 */
const splitIntArrayIntoQuotientAndRemainder = (integers, divisor = Math.pow(10, BACKUP_CHUNK_SIZE)) => { // eslint-disable-line max-len
  const res = { quotients: new List(), remainders: new List() };
  integers.forEach(int => {
    const quotient = Math.floor(int / divisor);
    const remainder = int - (quotient * divisor);
    res.quotients = res.quotients.push(quotient);
    res.remainders = res.remainders.push(remainder);
  });
  return res;
};

/**
 * Returns the y-coordinate that should be used when positioning a text
 * element inside the PDF. I think we need to do this little calculation
 * because of line height side effects.
 *
 * @param {integer} fontSize The element's font size.
 * @param {integer} yOffset The element's desired offset.
 *
 * @returns {integer}
 */
const yText = (fontSize, yOffset) => {
  const yZero = fontSize * 0.25;
  return yZero + yOffset;
};

/**
 * Creates new Master Key backup, syncs it with server, creates a local PDF
 * file allowing the user to retrieve the backup, and prompts the user to
 * save this file.
 *
 * @param {function} t i18next translate method.
 */
export const backupMasterKey = t => (dispatch, getState) => new Promise(async (resolve, reject) => {
  try {
    // Create an ephemeral key pair. This will be used to encrypt the Master
    // Key before storing it on the server.
    const worker = getWorkerPromise('crypto');
    const { publicKey, secretKey } = await worker({ event: CW_GENERATE_EPHEMERAL_KEY_PAIR });

    // Create backup code parts from the ephemeral key pair secret key.
    const bigEndianIntegers = await integerArrayToBigEndianArray(secretKey);
    const { quotients, remainders } = splitIntArrayIntoQuotientAndRemainder(bigEndianIntegers);

    // Save the data on server.
    const event = CW_EXTRACT_MASTER_KEYS;
    const { encryptedPrivateKey } = await worker({ event, publicKey, secretKey });
    await backupPushToServer(encryptedPrivateKey, quotients.toArray());

    // Indicate we created a backup and hide the yellow path.
    dispatch(RXBackupMasterKey(true));
    dispatch(RXUIGuideBackup(false));

    // Create a local PDF and prompt the user to save it.
    const { phone } = getState().user.profile;
    await createAndSaveBackupPDF(remainders, phone, t);
    return resolve();
  }
  catch (e) {
    return reject(e);
  }
});

/**
 * Reverse function for `backupMasterKey()`.
 *
 * @param {string} code 32 characters long code, no whitespace.
 * @param {string} token Access token.
 * @param {integer} [chunkSize=BACKUP_CHUNK_SIZE] Size of chunks for splitting the code.
 *
 * @returns {object} Master Key.
 * @property {string} publicKey
 * @property {string} secretKey
 */

export const recoverMasterKey =
  (code, token, chunkSize =
  BACKUP_CHUNK_SIZE) => (dispatch, getState) => new Promise(async (resolve, reject) => {
    if (code.length !== 32) throw new Error('Backup code must be 32 characters long.');

    // Construct back remainders from the QR code value.
    let remainders = new List();
    const parts = Math.ceil(code.length / chunkSize);
    for (let i = 0; i < parts; i += 1) {
      const start = i * chunkSize;
      const remainder = Validator.strToInt(code.substr(start, chunkSize));
      remainders = remainders.push(remainder);
    }

    try {
    // Construct back big endian integers array.
      const { ciphertext, quotient: quotients } = await getBackup(token);
      const bigEndianIntegers =
      quotients.map((quotient, i) => quotient * 10000 + remainders.get(i));

      // Construct back the ephemeral key pair we used to encrypt the Master Key.
      const secretKeyBytes = await bigEndianArrayToIntegerArray(bigEndianIntegers);
      const { publicKey, secretKey } = await derivePublicKeyFromSecretKey(secretKeyBytes);

      // Construct back the Master Key.
      const masterKey = await decryptMasterKey(ciphertext, publicKey, secretKey);
      return resolve(masterKey);
    }
    catch (e) {
      return reject(e);
    }
  });
// export const recoverMasterKey = async (code, token, chunkSize = BACKUP_CHUNK_SIZE) => {
//   if (code.length !== 32) throw new Error('Backup code must be 32 characters long.');

//   // Construct back remainders from the QR code value.
//   let remainders = new List();
//   const parts = Math.ceil(code.length / chunkSize);
//   for (let i = 0; i < parts; i += 1) {
//     const start = i * chunkSize;
//     const remainder = Validator.strToInt(code.substr(start, chunkSize));
//     remainders = remainders.push(remainder);
//   }

//   try {
//     // Construct back big endian integers array.
//     const { ciphertext, quotient: quotients } = await getBackup(token);
//     const bigEndianIntegers =
//     quotients.map((quotient, i) => quotient * 10000 + remainders.get(i));

//     // Construct back the ephemeral key pair we used to encrypt the Master Key.
//     const secretKeyBytes = await bigEndianArrayToIntegerArray(bigEndianIntegers);
//     const { publicKey, secretKey } = await derivePublicKeyFromSecretKey(secretKeyBytes);

//     // Construct back the Master Key.
//     const masterKey = await decryptMasterKey(ciphertext, publicKey, secretKey);
//     return masterKey;
//   }
//   catch (e) {
//     throw e;
//   }
// };
