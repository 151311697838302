/*
 * Copyright (C) 2018-2019 Nettoken Ltd. All rights reserved.
 *
 * This document is the property of Nettoken Ltd.
 * It is considered confidential and proprietary.
 *
 * This document may not be reproduced or transmitted in any form,
 * in whole or in part, without the express written permission of
 * Nettoken Ltd.
 */
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { sleep } from '@nettoken/helpers';
import { RXErrorShow } from 'main/error/reduxActions';
import CSSVars from 'environment/variables';
import TemplateSizes from './template-sizes';
import Sidebar from './wrapper';

class SidebarComponent extends React.Component {
  componentDidMount() {
    this.shiftDriftBubble();
  }

  componentDidUpdate() {
    this.shiftDriftBubble();
  }

  getDriftBubbleOffset() {
    if (this.props.active) {
      if (this.props.wide) {
        return CSSVars.swSidebarWide;
      }
      return CSSVars.swSidebar;
    }
    return 0;
  }

  shiftDriftBubble() {
    const offset = this.getDriftBubbleOffset();
    const node = document.querySelector('#drift-widget');
    if (node) {
      node.style.transition = 'transform 400ms';
      node.style.transform = `translateX(-${offset}px)`;
    }
  }

  async showError() {
    await sleep(CSSVars.trMove);
    const { t } = this.props;
    const title = t('error.404.title');
    const text = t('error.404.text');
    this.props.RXErrorShow(title, text);
  }

  renderTemplate() {
    const { template } = this.props;

    // For templates that should not be accessed.
    // if (template === 'privacy' && !dispatch(getFlag())) {
    //   this.showError();
    //   return null;
    // }

    try {
      // Request must include complete file name including extension to
      // remove ambiguity. DO NOT REMOVE OR CHANGE THIS. Otherwise,
      // Webpack will cause trouble during the build process by looping
      // through all files in directory and include unwanted files
      // such as tests or Storybook chapters.
      const module = require(`./templates/${template}/index.js`); // eslint-disable-line global-require
      const Template = module.default;
      return <Template />;
    }
    catch (e) {
      this.showError();
      return null;
    }
  }

  render() {
    if (!this.props.template) return null;

    return (
     <>
     {this.props.statata &&
      <Sidebar
        active={this.props.active}
        wide={this.props.wide}>
        {this.renderTemplate()}
      </Sidebar>
  }
      </>
    );
  }
}

const mapStateToProps = state => {
  const template = state.ui.sidebarTemplate;
  return {
    active: state.ui.sidebar,
    template,
    wide: TemplateSizes[template] === 'large',
    statata: state.session.accessToken,
  };
};

const mapDispatchToProps = dispatch => ({
  RXErrorShow: (title, text) => dispatch(RXErrorShow(title, text)),
});

export default translate()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SidebarComponent));
